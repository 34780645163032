import { configureStore } from "@reduxjs/toolkit";

import {
  reportsSlice,
  ReportsState,
  initialState as reportsState,
} from "./reports";

import {
  referralSlice,
  ReferralState,
  initialState as referralState,
} from "./referrals";

import { uiSlice, UIState, initialState as uiState } from "./ui";
import {
  companySlice,
  CompanyState,
  initialState as companyState,
} from "./company";

import { Api } from "../Network/Api";
import {
  campaignsSlice,
  CampaignsState,
  initialState as campaignsState,
} from "./campaigns";

export type RootState = {
  reports: ReportsState;
  ui: UIState;
  company: CompanyState;
  campaigns: CampaignsState;
  referrals: ReferralState;
};

export type ThunkExtra = {
  extra: {
    api: Api;
  };
};

export const createStore = (api: Api) =>
  configureStore({
    reducer: {
      reports: reportsSlice.reducer,
      ui: uiSlice.reducer,
      company: companySlice.reducer,
      campaigns: campaignsSlice.reducer,
      referrals: referralSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: {
            api,
          },
        },
      }),
  });

export const initialRootState: RootState = {
  reports: reportsState,
  ui: uiState,
  company: companyState,
  campaigns: campaignsState,
  referrals: referralState,
};
