import Tippy from "@tippyjs/react";
import React, { PropsWithChildren } from "react";
import styles from "./Tooltip.module.scss";

interface TooltipProps {
  text: string;
}

export const Tooltip = ({
  text,
  children,
}: PropsWithChildren<TooltipProps>) => {
  return (
    <Tippy
      allowHTML={true}
      animation={false}
      render={() => <p className={styles.tooltip}>{text}</p>}
    >
      <div className={styles.tooltipContent}>{!!children && children}</div>
    </Tippy>
  );
};
