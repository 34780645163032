import React from "react";
import classNames from "classnames";

import styles from "./ColumnLayout.module.scss";

export interface ColumnLayoutColumn {
  cells: React.ReactElement[];
}

export interface ColumnLayoutProps {
  className?: string;
  columns: ColumnLayoutColumn[];
}

function ColumnLayout({ className, columns }: ColumnLayoutProps) {
  return (
    <div
      className={classNames(styles.columnLayout, className)}
    >
      {columns.map((column) => (
        <div className={classNames(styles.column)} key={columns.findIndex(co => co === column)}>
          {column.cells}
        </div>
      ))}
    </div>
  );
}

export { ColumnLayout };
