import { RadioItem } from "./RadioItem";
import styles from "./RadioGroup.module.scss";

interface RadioGroupProps {
  currentValue?: any;
  items: { label: string; value: any }[];
  onValueChange: (selection?: any) => void;
  wrap?: boolean;
}

export const RadioGroup = ({
  currentValue,
  items,
  onValueChange,
  wrap = true,
}: RadioGroupProps) => {
  return (
    <div
      className={styles.radioGroup}
      style={{ flexWrap: wrap ? "wrap" : "nowrap" }}
    >
      {items.map((radio) => (
        <RadioItem
          label={radio.label}
          value={radio.value}
          checked={radio.value === currentValue}
          onCheck={onValueChange}
          key={radio.label}
        />
      ))}
    </div>
  );
};
