import {
  SmallDataTable,
  SmallDataTableRow,
  SmallDataTableSection,
} from "../SmallDataTable";

interface ReferralDataTableProps {
  data: {
    totalCount: number;
    views: number;
    storeOpens: number;
    installs: number;
  };
}

export const ReferralDataTable = ({ data }: ReferralDataTableProps) => {
  return (
    <SmallDataTable>
      <SmallDataTableSection title="Referrals">
        <SmallDataTableRow title={"Anzahl"}>
          {data.totalCount}
        </SmallDataTableRow>
        <SmallDataTableRow title={"Views"}>{data.views}</SmallDataTableRow>
        <SmallDataTableRow title={"Store opens"}>
          {data.storeOpens}
        </SmallDataTableRow>
        <SmallDataTableRow title={"Installs"}>
          {data.installs}
        </SmallDataTableRow>
      </SmallDataTableSection>
    </SmallDataTable>
  );
};
