import classNames from "classnames";
import styles from "./AppHeaderBarShortStatistics.module.scss";

interface AppHeaderBarItem {
  title: string;
  value: string;
}

interface AppHeaderBarShortStatisticsProps {
  newUsers?: number;
  newSessions?: number;
}

function AppHeaderBarShortStatistics({
  newUsers,
  newSessions,
}: AppHeaderBarShortStatisticsProps) {
  const items: AppHeaderBarItem[] = [
    { title: "Neue Nutzer", value: newUsers ? String(newUsers) : "-" },
    { title: "Sessions", value: newSessions ? String(newSessions) : "-" },
    { title: "Add-Impressions", value: "-" },
    { title: "Klicks", value: "-" },
    { title: "Auslastung", value: "-" },
  ];

  function renderSingleItem(item: AppHeaderBarItem): JSX.Element {
    return (
      <div className={styles.item} key={item.title}>
        <p>{item.title}:</p>
        <p>{item.value}</p>
      </div>
    );
  }

  return (
    <div className={classNames(styles.appHeaderBarShortStatistics)}>
      {items.map((item) => renderSingleItem(item))}
    </div>
  );
}

export { AppHeaderBarShortStatistics };
