import classNames from "classnames";
import { useCallback, useRef, useState } from "react";

import styles from "./Dropdown.module.scss";

import { useOutsideAlerter } from "../../Hooks/useOutsideAlerter";
import { FormLabel } from "./FormLabel";

export interface DropdownElement {
  label: string;
  value: string | number;
}

interface MultiselectProps {
  title?: string;
  className?: string;
  elements: DropdownElement[];
  selectedElement?: number | string;
  onChange: (selectedElement: number | string) => void;
  zIndex?: number;
}

export const Dropdown = ({
  elements,
  title,
  className,
  zIndex = 100,
  onChange,
  selectedElement,
}: MultiselectProps) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  useOutsideAlerter(() => setDropdownVisible(false), dropdownRef);

  const handleValueToggle = useCallback(
    (value: number | string) => {
      if (selectedElement !== value) {
        onChange(value);
        setDropdownVisible(false);
      }
    },
    [selectedElement, onChange]
  );

  return (
    <div className={className}>
      {title && <FormLabel title={title} />}
      <div
        className={styles.multiselect}
        onFocus={() => setDropdownVisible(true)}
      >
        <div className={styles.dropwdownText} role="button" tabIndex={0}>
          <p className={styles.currentSelection}>
            {selectedElement &&
              elements.find((e) => e.value === selectedElement)?.label}
          </p>
        </div>
        <div className={styles.relativeDropdownContainer}>
          <div
            className={classNames(styles.dropdown, {
              [styles.visible]: dropdownVisible,
            })}
            style={{ zIndex: zIndex }}
            ref={dropdownRef}
          >
            {elements.map((element, index) => (
              <div
                className={classNames(styles.dropdownRow, {
                  [styles.selected]: selectedElement === element.value,
                })}
                key={`${element.label}-${index}`}
                tabIndex={0}
                onClick={() => handleValueToggle(element.value)}
                onFocus={() => setDropdownVisible(true)}
              >
                <p>{element.label}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
