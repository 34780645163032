import classNames from "classnames";
import React from "react";
import { useImageExists } from "../Hooks/useImageExists";
import styles from "./OptionalImage.module.scss";
interface OptionalImageProps {
  url: string;
  className?: string;
}

export const OptionalImage = ({ url, className }: OptionalImageProps) => {
  const { imageExists } = useImageExists(url);

  return (
    <div
      className={classNames(
        styles.container,
        {
          [styles.noImage]: !imageExists,
        },
        className
      )}
    >
      {imageExists && (
        <img
          alt="Banner Ressource of the campaign"
          src={url}
          className={styles.bannerImage}
        />
      )}
      {!imageExists && <p>?</p>}
    </div>
  );
};
