import styles from "./ConfirmDialog.module.scss";
import { Button } from "./Controls/Button";

interface ConfirmDialogProps {
  title: string;
  message: string;
  isOpen: boolean;

  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmDialog = ({
  isOpen,
  title,
  message,
  onConfirm,
  onCancel,
}: ConfirmDialogProps) => {
  return (
    <>
      {isOpen && (
        <div className={styles.background}>
          <div className={styles.dialog}>
            <h3>{title}</h3>
            <p>{message}</p>
            <div className={styles.buttonContainer}>
              <Button title="Abbrechen" color="grey" onClick={onCancel} />
              <Button title="Bestätigen" onClick={onConfirm} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
