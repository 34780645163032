import { Report, ReportResponse } from "@stududu-gmbh/stududu-api-definitions";

export async function fetchCurrentReport(
  token: string
): Promise<Report | undefined> {
  const url = "/reports-service/api/v1/report";

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-url-encoded",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Wrong response while fetching report");
    }

    const report = (await response.json()) as ReportResponse;
    return report.data;
  } catch (error) {
    console.error(error);
  }
}
