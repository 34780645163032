import * as authenticationApi from "./authenticationApi";
import * as reportsApi from "./reportsApi";
import * as marketingApi from "./marketingApi";
import * as referralApi from "./referralApi";

export class Api {
  public authenticationApi = authenticationApi;
  public reportsApi = reportsApi;
  public marketingApi = marketingApi;
  public referralApi = referralApi;
}
