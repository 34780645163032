import classNames from "classnames";
import styles from "./SidebarButtonGroup.module.scss";

import { SidebarButton, SidebarButtonProps } from "./SidebarButton";

export interface SidebarButtonGroupProps {
  className?: string;
  selectedItem: number;
  buttons: SidebarButtonProps[];
  headingsAreVisible: boolean;
  selectedItemChanged?: (newSelectedItem: number) => void;
}

function SidebarButtonGroup({
  className,
  buttons,
  headingsAreVisible,
}: SidebarButtonGroupProps) {
  const setNewSelectedItem = (newIndex: number) => {
    buttons[newIndex].action?.();
  };

  return (
    <div className={classNames(className, styles.sidebarButtonGroup)}>
      {buttons.map((button, index) => (
        <SidebarButton
          title={button.title}
          icon={button.icon}
          isActiveButton={button.isActiveButton}
          isDestructive={button.isDestructive}
          type={button.type}
          action={() => setNewSelectedItem(index)}
          headingIsVisible={headingsAreVisible}
          key={index}
        />
      ))}
    </div>
  );
}

export { SidebarButtonGroup };
