import { useCallback } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import styles from "./AppHeaderBar.module.scss";
import { AppHeaderBarShortStatistics } from "../Components/AppHeaderBarShortStatistics";

import hamburgerIcon from "../Assets/Icons/icon-hamburger.svg";

import { useSelector } from "react-redux";
import { selectReport } from "../Stores/reports";
import { uiSlice } from "../Stores/ui";

function AppHeaderBar() {
  const dispatch = useDispatch();
  const report = useSelector(selectReport);

  const toggleSidebarState = useCallback(() => {
    dispatch(uiSlice.actions.toggleSidebarCollapsed());
  }, [dispatch]);

  return (
    <div className={classNames(styles.appHeaderBarContainer)}>
      <div className={classNames(styles.appHeaderBar)}>
        <div className={classNames(styles.appHeaderBarLeftContainer)}>
          <button onClick={toggleSidebarState}>
            <img src={hamburgerIcon} alt=""></img>
          </button>
        </div>
        <AppHeaderBarShortStatistics
          newUsers={report?.reports.general?.users?.new}
          newSessions={report?.reports.general?.sessions?.new}
        />
      </div>
    </div>
  );
}

export { AppHeaderBar };
