import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import { useLocation, useNavigate } from "react-router-dom";

import {
  SelectedPage,
  selectSelectedPage,
  selectSidebarCollapsed,
  uiSlice,
} from "../Stores/ui";

import { actions as companyActions } from "../Stores/company";

import styles from "./HomeSidebar.module.scss";

import { Sidebar } from "../Components/Sidebar";
import { SidebarButtonGroup } from "../Components/SidebarButtonGroup";

import stududuAppicon from "../Assets/Images/stududu-appicon.jpg";

import dashboardIcon from "../Assets/Icons/icon-dashboard.svg";
import statisticIcon from "../Assets/Icons/icon-statistic.svg";
import activeIcon from "../Assets/Icons/icon-active.svg";
import referralIcon from "../Assets/Icons/icon-referral.svg";
import targetIcon from "../Assets/Icons/icon-target.svg";
import { fetchReport } from "../Stores/reports";

function HomeSidebar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedPage = useSelector(selectSelectedPage);
  const sidebarCollapsed = useSelector(selectSidebarCollapsed);
  const tabButtonClicked = useCallback(
    (identifier: SelectedPage) => {
      let newPath = "";
      switch (identifier) {
        case "dashboard":
          newPath = "/";
          break;
        case "statistics":
          newPath = "/statistics";
          break;
        case "campaigns":
          newPath = "/campaigns";
          break;
        case "referrals":
          newPath = "/referrals";
          break;
        case "target-analysis":
          newPath = "/target-analysis";
          break;
      }
      navigate(newPath);
      dispatch(uiSlice.actions.setNewPage(identifier));
    },
    [dispatch, navigate]
  );

  const currentLink = useLocation();

  useEffect(() => {
    if (currentLink.pathname.includes("/statistics")) {
      dispatch(uiSlice.actions.setNewPage("statistics"));
    } else if (currentLink.pathname.includes("/campaigns")) {
      dispatch(uiSlice.actions.setNewPage("campaigns"));
    } else if (currentLink.pathname.includes("/referrals")) {
      dispatch(uiSlice.actions.setNewPage("referrals"));
    } else if (currentLink.pathname.includes("/target-analysis")) {
      dispatch(uiSlice.actions.setNewPage("target-analysis"));
    } else {
      dispatch(uiSlice.actions.setNewPage("dashboard"));
    }
  }, [currentLink, dispatch]);

  const onLogout = useCallback(() => {
    dispatch(companyActions.deleteTokenFromStorage());
  }, [dispatch]);

  const onForceNewReport = useCallback(() => {
    dispatch(fetchReport());
  }, [dispatch]);

  const onOpenSettings = useCallback(() => {}, []);

  return (
    <Sidebar
      isCollapsed={sidebarCollapsed}
      onLogout={onLogout}
      onOpenSettings={onOpenSettings}
      onForceNewReport={onForceNewReport}
    >
      <div className={classNames(styles.appiconContainer)}>
        <img
          className={classNames(styles.appicon, {
            [styles.collapsed]: sidebarCollapsed,
          })}
          src={stududuAppicon}
          alt="stududu appicon"
        />
      </div>

      <SidebarButtonGroup
        selectedItem={0}
        headingsAreVisible={!sidebarCollapsed}
        buttons={[
          {
            title: "Dashboard",
            icon: dashboardIcon,
            isActiveButton: selectedPage === "dashboard",
            isDestructive: false,
            type: "navigation",
            action: () => tabButtonClicked("dashboard"),
          },
          {
            title: "Statistiken",
            icon: statisticIcon,
            isActiveButton: selectedPage === "statistics",
            isDestructive: false,
            type: "navigation",
            action: () => tabButtonClicked("statistics"),
          },
          {
            title: "Kampagnen",
            icon: activeIcon,
            isActiveButton: selectedPage === "campaigns",
            isDestructive: false,
            type: "navigation",
            action: () => tabButtonClicked("campaigns"),
          },
          {
            title: "Links",
            icon: referralIcon,
            isActiveButton: selectedPage === "referrals",
            isDestructive: false,
            type: "navigation",
            action: () => tabButtonClicked("referrals"),
          },
          {
            title: "Zielgruppen-Analyse",
            icon: targetIcon,
            isActiveButton: selectedPage === "target-analysis",
            isDestructive: false,
            type: "navigation",
            action: () => tabButtonClicked("target-analysis"),
          },
        ]}
      />
    </Sidebar>
  );
}

export { HomeSidebar };
