import classNames from "classnames";
import React from "react";

import styles from "./DefaultCell.module.scss";

export interface DefaultCellProps {
  title: string;
  subtitle?: string;
}

const DefaultCell: React.FunctionComponent<DefaultCellProps> = ({
  title,
  subtitle,
  children,
}) => {
  return (
    <div className={classNames(styles.dashboardStandardCell)}>
      <h3 className={classNames(styles.heading)}>{title}</h3>
      {subtitle !== undefined && (
        <p className={classNames(styles.subheading)}>{subtitle}</p>
      )}
      <div className={classNames(styles.contentContainer)}>{children}</div>
    </div>
  );
};

export { DefaultCell };
