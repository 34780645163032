import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { Button } from "./Controls/Button";
import styles from "./DangerZone.module.scss";

interface DangerZoneRowProps {
  title: string;
  text: string;
  button: {
    title: string;
    onClick: () => void;
  };
}

export const DangerZoneRow = ({ title, text, button }: DangerZoneRowProps) => {
  return (
    <div className={classNames(styles.dangerZoneRow)}>
      <div className={classNames(styles.textContainer)}>
        <strong>{title}</strong>
        <p>{text}</p>
      </div>
      <Button
        title={button.title}
        size="small"
        variant="destructive"
        onClick={button.onClick}
        type='button'
      ></Button>
    </div>
  );
};

interface DangerZoneProps {}

export const DangerZone = ({
  children,
}: PropsWithChildren<DangerZoneProps>) => {
  return (
    <>
      <h3 className={styles.heading}>Danger Zone</h3>
      <div className={styles.dangerZone}>{children}</div>
    </>
  );
};
