import { useEffect, useState } from "react";
import { checkIfImageExists } from "../Utils/functions/checkIfImageExists";

export function useImageExists(url: string): { imageExists: boolean } {
  const [imageExists, setImageExists] = useState(false);

  useEffect(() => {
    const check = async () => {
      const result = await checkIfImageExists(url);
      setImageExists(result);
    };
    check();
  }, [url]);

  return {
    imageExists,
  };
}
