import classNames from "classnames";
import { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import styles from "./SchoolTypeDistributionCell.module.scss";
import { selectReport } from "../../../Stores/reports";
import { GraphDataPoint } from "../../../Models";

import { BarGraph, DefaultCell } from "../../../Components";
import { ReportDataPoint } from "@stududu-gmbh/stududu-api-definitions";


function SchoolTypeDistributionCell() {
  const report = useSelector(selectReport);

  const [dataPoints, setDataPoints] = useState<GraphDataPoint[]>([]);

  const createDataPoint = useCallback(
    (schoolType: ReportDataPoint): GraphDataPoint => {
      return {
        name: schoolType.label ?? "",
        value: schoolType.value ?? 0,
        color: "",
      };
    },
    []
  );

  useEffect(() => {
    setDataPoints(
      (report?.reports.general?.targetGroups?.schoolTypes ?? []).map(
        (schoolType) => createDataPoint(schoolType)
      )
    );
  }, [report, createDataPoint]);

  return (
    <DefaultCell title="Schulform">
      <div className={classNames(styles.chart)}>
        <BarGraph dataPoints={dataPoints} />
      </div>
    </DefaultCell>
  );
}

export { SchoolTypeDistributionCell };
