import styles from "./CampaignCreationForm.module.scss";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import { CampaignCreationGeneral } from "./creation/sections/CampaignCreationGeneral";
import { Campaign } from "@stududu-gmbh/stududu-api-definitions";
import { CampaignCreationDuration } from "./creation/sections/CampaignCreationDuration";
import { CampaignCreationTarget } from "./creation/sections/CampaignCreationTarget";

export interface CampaignCreationFormProps {
  watch: UseFormWatch<Campaign>;
  setValue: UseFormSetValue<Campaign>;
}

export const CampaignCreationForm = ({
  watch,
  setValue,
}: CampaignCreationFormProps) => {
  return (
    <>
      <div className={styles.box}>
        <CampaignCreationGeneral watch={watch} setValue={setValue} />
        <CampaignCreationDuration watch={watch} setValue={setValue} />
        <CampaignCreationTarget watch={watch} setValue={setValue} />
      </div>
    </>
  );
};
