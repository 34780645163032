import React from "react";

import styles from "./DashboardAdvertisementsCell.module.scss";

import classNames from "classnames";

import { DefaultCell } from "../../../Components/DefaultCell";
import { CellBubble } from "../../../Components";

const DashboardAdvertisementsCell: React.FunctionComponent = () => {
  //let { report } = useLastReport(false);

  return (
    <DefaultCell title="Werbung" subtitle="in den letzten 24h">
      <div className={classNames("bubbleContainer")}>
        <CellBubble info="Kampagnen" value={"-"}></CellBubble>
        <CellBubble info="Impressionen" value={"-"}></CellBubble>
        <CellBubble info="Klicks" value={"-"}></CellBubble>
        <CellBubble info="Auslastung" value={"-"}></CellBubble>
      </div>
    </DefaultCell>
  );
};

export { DashboardAdvertisementsCell };
