import { useState, useEffect } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { selectReport } from "../../../Stores/reports";
import { GraphDataPoint } from "../../../Models";
import styles from "./DeviceTypeDistributionCell.module.scss";

import {
  DefaultCell,
  ChartLegend,
  DistributionBarChart,
} from "../../../Components";

function DeviceTypeDistributionCell() {
  const report = useSelector(selectReport);

  const [dataPoints, setDataPoints] = useState<GraphDataPoint[]>([]);

  useEffect(() => {
    let presavedDataPoints: GraphDataPoint[] = [];

    if (report?.reports.general?.devices?.deviceTypes?.phone) {
      presavedDataPoints.push({
        name: "Smartphone",
        value: report?.reports.general?.devices?.deviceTypes?.phone ?? 0,
        color: "#27AE60",
      });
    }
    if (report?.reports.general?.devices?.deviceTypes?.tablet) {
      presavedDataPoints.push({
        name: "Tablet",
        value: report?.reports.general?.devices?.deviceTypes?.tablet ?? 0,
        color: "#2980B9",
      });
    }
    setDataPoints(presavedDataPoints);
  }, [report]);

  return (
    <DefaultCell title="Gerätetyp">
      <div className={classNames(styles.chart)}>
        <DistributionBarChart elements={dataPoints} />
        <ChartLegend elements={dataPoints} />
      </div>
    </DefaultCell>
  );
}

export { DeviceTypeDistributionCell };
