import classNames from "classnames";
import { useSelector } from "react-redux";

import moment from "moment";
import "moment/locale/de";

import styles from "./OSDistributionCell.module.scss";
import { selectReport } from "../../../Stores/reports";

import { DefaultCell } from "../../../Components";
import { BarChart } from "../../../Components/BarChart";

export const TotalSessionsPerMonthCell = () => {
  const report = useSelector(selectReport);

  return (
    <DefaultCell title="Gesamt Sessions pro Monat">
      <div className={classNames(styles.chart)}>
        <BarChart
          dataPoints={
            report?.reports.general?.users?.sessionsPerMonth?.map(
              (dataPoint) => ({
                label: moment(dataPoint.label)
                  .locale("de")
                  .format("MMMM YYYY"),
                value: dataPoint.value,
              })
            ) ?? []
          }
          barColor="#2980B9"
        />
      </div>
    </DefaultCell>
  );
};
