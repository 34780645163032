import classNames from "classnames";
import { LoadingSpinner } from "../LoadingSpinner";

import styles from "./Button.module.scss";

export interface ButtonProps {
  className?: string;
  title: string;
  variant?: "filled" | "outlined" | "destructive";
  size?: "default" | "small";
  color?: "primary" | "grey";
  type?: "button" | "submit" | "reset" | undefined;
  loading?: boolean;
  onClick?: () => void;
}

export const Button = ({
  className,
  title,
  variant = "filled",
  size = "default",
  color = "primary",
  type,
  loading = false,
  onClick,
}: ButtonProps) => {

  console.log(loading)
  return (
    <button
      className={classNames(className, styles.button, {
        [styles.primary]: color === "primary",
        [styles.grey]: color === "grey",
        [styles.destructive]: variant === "destructive",
        [styles.small]: size === "small",
      })}
      onClick={(e) => {
        e.preventDefault();
        onClick?.();
      }}
      type={type}
    >
      {loading ? <LoadingSpinner /> : title}
    </button>
  );
};
