import classNames from "classnames";
import styles from "./Toggle.module.scss";

interface ToggleProps {
  active: boolean;
  onToggle: () => void;
}

export const Toggle = ({ active, onToggle }: ToggleProps) => {
  return (
    <div
      className={classNames(styles.toggle, { [styles.active]: active })}
      onClick={onToggle}
    >
      <div className={classNames(styles.handle, { [styles.active]: active })} />
    </div>
  );
};
