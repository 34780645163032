import classNames from "classnames";
import { useSelector } from "react-redux";

import moment from "moment";
import "moment/locale/de";

import styles from "./OSDistributionCell.module.scss";
import { selectReport } from "../../../Stores/reports";

import { DefaultCell } from "../../../Components";
import { BarChart } from "../../../Components/BarChart";

export const LastDaysDownloadsCell = () => {
  const report = useSelector(selectReport);

  return (
    <DefaultCell title="Downloads" subtitle="Letze 30 Tage">
      <div className={classNames(styles.chart)}>
        <BarChart
          dataPoints={
            report?.reports.general?.users?.perDay.map((dataPoint) => ({
              label:
                dataPoint.label &&
                moment(new Date(dataPoint.label))
                  .locale("de")
                  .format("dddd, DD.MM.YY"),
              value: dataPoint.value,
            })) ?? []
          }
        />
      </div>
    </DefaultCell>
  );
};
