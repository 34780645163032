import classNames from "classnames";

import styles from "./Dashboard.module.scss";

import { CellRow, DefaultCell, ColumnLayout } from "../../Components";
import { DashboardAdvertisementsCell } from "./Cells";
import { TrendingPLZCell, UsageOverviewCell } from "../Statistics/Cells";

function Dashboard() {
  return (
    <div className={classNames(styles.dashboardContainer)}>
      <ColumnLayout
        className={classNames(styles.dashboard)}
        columns={[
          {
            cells: [<UsageOverviewCell />, <DashboardAdvertisementsCell />],
          },
          {
            cells: [
              <DefaultCell title="Aktive Kampagnen">
                <CellRow title="Keine aktiven Kampagnen" />
              </DefaultCell>,
            ],
          },
          {
            cells: [<TrendingPLZCell />],
          },
        ]}
      />
    </div>
  );
}

export { Dashboard };
