export function getNumerStringWithPoints(
  number?: number,
  defaultValue: string = "-"
): string {
  if (number !== undefined) {
    return number
      .toString()
      .replace(".", ",")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  } else {
    return defaultValue;
  }
}

export function getPercentageString(number: number) {
  return `${(number * 100).toFixed(2).replace(".", ",")}%`;
}

export function getShortNumberString(number: number) {
  return `${number.toFixed(2).replace(".", ",")}`;
}