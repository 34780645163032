import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Page } from "../../Components/Page";
import { PageContent } from "../../Components/PageContent";

import {
  deleteReferral,
  fetchReferrals,
  selectReferralEvents,
  selectTotalStats,
} from "../../Stores/referrals";
import styles from "./Referrals.module.scss";

import { CopyToClipboard } from "react-copy-to-clipboard";

import linkIcon from "../../Assets/Icons/icon-link.svg";
import deleteIcon from "../../Assets/Icons/icon-delete.svg";
import { Modal } from "../../Components/Modal";
import { CreateReferral } from "../../Widgets/Referrals/CreateReferral";
import { actions, selectReferralCreationOpen } from "../../Stores/ui";
import { ReferralDataTable } from "../../Components/Referral/ReferralDataTable";
import { ReferralEvent } from "@stududu-gmbh/shared-interfaces";
import { ConfirmDialog } from "../../Components/ConfirmDialog";

export const Referrals = () => {
  const dispatch = useDispatch();
  const { referralEvents, referralEventsLoading } =
    useSelector(selectReferralEvents);
  const { totalViews, totalStoreOpen } = useSelector(selectTotalStats);

  const referralCreationOpen = useSelector(selectReferralCreationOpen);

  const [confirmDialogIsOpen, setConfirmDialogIsOpen] =
    useState<boolean>(false);

  const [lastClickedReferralEvent, setLastClickedReferralEvent] = useState<
    ReferralEvent | undefined
  >(undefined);

  useEffect(() => {
    dispatch(fetchReferrals());
  }, [dispatch]);

  const handleCreationOpen = useCallback(() => {
    dispatch(actions.openReferralCreation());
  }, [dispatch]);

  const handleCreationClose = useCallback(() => {
    dispatch(actions.closeReferralCreation());
  }, [dispatch]);

  const handleReferralDelete = useCallback(
    (referral: ReferralEvent) => {
      dispatch(deleteReferral(referral));
    },
    [dispatch]
  );

  return (
    <>
      <Page title="Links" onAddNew={handleCreationOpen}>
        <PageContent
          mainView={
            <table className={classNames(styles.table)}>
              <th className={styles.leftColumn}>Name</th>
              <th className={styles.defaultColoumn}>Aufrufe</th>
              <th className={styles.defaultColoumn}>Store open</th>
              <th className={styles.defaultColoumn}>Installs</th>
              <th className={styles.defaultColoumn}>Aktionen</th>

              {referralEvents?.length > 0 &&
                !referralEventsLoading &&
                referralEvents.map((event) => (
                  <tr
                    className={classNames(styles.tableRow)}
                    key={event.referralCode}
                  >
                    <td
                      className={classNames(
                        styles.tableCell,
                        styles.leftColumn
                      )}
                    >
                      {`${event.name} (${event.referralCode})`}
                    </td>
                    <td className={classNames(styles.tableCell)}>
                      {event.views}
                    </td>
                    <td className={classNames(styles.tableCell)}>
                      {event.storeOpens}
                    </td>
                    <td className={classNames(styles.tableCell)}>
                      {event.installs}
                    </td>
                    <td
                      className={classNames(
                        styles.tableCell,
                        styles.actionButtonCell
                      )}
                    >
                      <CopyToClipboard
                        text={`https://get.stududu.app?${event.referralCode}`}
                      >
                        <button className={styles.actionButton}>
                          <img alt="Link icon" src={linkIcon}></img>
                        </button>
                      </CopyToClipboard>
                      <button
                        className={styles.actionButton}
                        onClick={() => {
                          setLastClickedReferralEvent(event);
                          setConfirmDialogIsOpen(true);
                        }}
                      >
                        <img alt="Delete icon" src={deleteIcon}></img>
                      </button>
                    </td>
                  </tr>
                ))}
            </table>
          }
          sideView={
            <ReferralDataTable
              data={{
                totalCount: referralEvents.length,
                views: totalViews,
                storeOpens: totalStoreOpen,
                installs: 0,
              }}
            />
          }
        />
      </Page>
      <Modal
        isOpen={referralCreationOpen}
        content={<CreateReferral />}
        onClose={handleCreationClose}
        onConfirm={() => console.log()}
      />
      <ConfirmDialog
        title={"Bist du sicher?"}
        message={"Bist du sicher, dass du den Referral-Link löschen willst?"}
        isOpen={confirmDialogIsOpen}
        onCancel={() => {
          setConfirmDialogIsOpen(false);
          setLastClickedReferralEvent(undefined);
        }}
        onConfirm={() => {
          if (lastClickedReferralEvent) {
            handleReferralDelete(lastClickedReferralEvent);
          }
          setLastClickedReferralEvent(undefined);
          setConfirmDialogIsOpen(false);
        }}
      />
    </>
  );
};
