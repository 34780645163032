import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { postCampaign } from "../../Stores/campaigns";
import { actions, selectCampaignCreationOpen } from "../../Stores/ui";
import { CampaignCreationForm } from "../../Components/Campaigns/CampaignCreationForm";
import { Modal } from "../../Components/Modal";
import { Campaign } from "@stududu-gmbh/stududu-api-definitions";
import { useForm } from "react-hook-form";

export const CampaignCreation = () => {
  const dispatch = useDispatch();
  const { setValue, watch, reset } = useForm<Campaign>({
    defaultValues: {
      campaignPaymentType: "PREPAID",
      prepaidImpressions: 10000,
      duration: "THREE_MONTHS",
      schoolTypes: [],
      grades: [],
    },
  });

  const campaignCreationOpen = useSelector(selectCampaignCreationOpen);

  const onCampaignSave = useCallback(() => {
    dispatch(postCampaign(watch()));
    dispatch(actions.closeCampaignCreation());
  }, [dispatch, watch]);

  const handleCampaignClose = useCallback(() => {
    dispatch(actions.closeCampaignCreation());
  }, [dispatch]);

  useEffect(() => {
    if (campaignCreationOpen) {
      reset();
    }
  }, [campaignCreationOpen, reset]);

  return (
    <Modal
      isOpen={campaignCreationOpen}
      title={"Neue Kampagne erstellen"}
      onClose={handleCampaignClose}
      onConfirm={onCampaignSave}
      content={<CampaignCreationForm setValue={setValue} watch={watch} />}
    />
  );
};
