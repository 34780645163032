import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import {
  authenticateUser,
  selectAuthentication,
  selectLoginError,
} from "../../Stores/company";
import image from "../../Assets/Images/stududu-login-background-image.jpg";
import styles from "./Login.module.scss";
import { TextField } from "../../Components/Controls/TextField";
import { ErrorInfo } from "../../Components/ErrorInfo";
import { Button } from "../../Components/Controls/Button";

const Login = () => {
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  const { loginError } = useSelector(selectLoginError);

  const { authenticationLoading } = useSelector(selectAuthentication);

  const onLogin = useCallback(() => {
    dispatch(authenticateUser({ userName, password }));
  }, [userName, password, dispatch]);

  return (
    <div
      className={styles.login}
      style={{ backgroundImage: "url(" + image + ")" }}
    >
      <form className={styles.contentContainer}>
        <TextField
          title={""}
          value={userName}
          placeholder="Nutzername"
          onValueChange={(text) => setUsername(text)}
        />
        <TextField
          title={""}
          value={password}
          placeholder="Passwort"
          type="password"
          onValueChange={(text) => setPassword(text)}
        />
        {loginError && (
          <ErrorInfo
            text={
              loginError === "INVALID_CREDENTIALS"
                ? "Benutzername oder Passwort scheinen falsch zu sein"
                : "Es ist ein Serverfehler aufgetreten"
            }
          />
        )}
        <Button
          className={classNames("primaryButton", styles.loginButton)}
          onClick={() => {
            console.log("dings");
            onLogin();
          }}
          loading={authenticationLoading}
          title="Einloggen"
          type="submit"
        />
      </form>
    </div>
  );
};

export { Login };
