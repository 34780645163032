import classNames from "classnames";
import { useSelector } from "react-redux";

import moment from "moment";
import "moment/locale/de";

import styles from "../OSDistributionCell.module.scss";
import { selectReport } from "../../../../Stores/reports";

import { DefaultCell } from "../../../../Components";
import { BarChart } from "../../../../Components/BarChart";

export const ImpressionsPerMonthCell = () => {
  const report = useSelector(selectReport);

  return (
    <DefaultCell title="Impressions" subtitle="pro Monat">
      <div className={classNames(styles.chart)}>
        <BarChart
          dataPoints={
            report?.reports.marketing?.impressionsPerMonth?.map(
              (dataPoint) => ({
                label:
                  dataPoint.label &&
                  moment(new Date(dataPoint.label))
                    .locale("de")
                    .format("MMMM YYYY"),
                value: dataPoint.value,
              })
            ) ?? []
          }
        />
      </div>
    </DefaultCell>
  );
};
