import { ReportDataPoint } from "@stududu-gmbh/stududu-api-definitions";
import Tippy from "@tippyjs/react";
import { useEffect, useState } from "react";
import styles from "./BarChart.module.scss";

interface BarChartProps {
  dataPoints: ReportDataPoint[];
  barColor?: string;
}

export const BarChart = ({ dataPoints = [], barColor }: BarChartProps) => {
  const [span, setSpan] = useState<number>(1);
  const [minValue, setMinValue] = useState(0.7);

  useEffect(() => {
    if (dataPoints.length === 0) {
      setSpan(1);
      setMinValue(0.7);
      return;
    }

    const minValue = Math.min(...dataPoints.map((values) => values.value ?? 0));
    const maxValue = Math.max(...dataPoints.map((values) => values.value ?? 0));

    setMinValue(minValue);
    if (maxValue - minValue !== 0) {
      setSpan(maxValue - minValue);
    }
  }, [dataPoints]);

  return (
    <div
      className={styles.container}
      style={{
        gridTemplateColumns: `repeat(${dataPoints.length}, 1fr)`,
      }}
    >
      {dataPoints.map((point) => (
        <div
          key={`${point.label}-${point.value}`}
          className={styles.barContainer}
        >
          <Tippy
            allowHTML={true}
            render={() => (
              <p className={"tooltip"}>{`${point.label}: ${point.value}`}</p>
            )}
            animation={false}
          >
            <div
              className={styles.bar}
              style={{
                height: `calc(${
                  ((point.value ?? 0 - minValue) / span) * 100
                }% + 2px)`,
                backgroundColor: barColor ?? "#27AE60",
              }}
            />
          </Tippy>
        </div>
      ))}
    </div>
  );
};
