import classNames from "classnames";

import {
  OSDistributionCell,
  SchoolTypeDistributionCell,
  DeviceTypeDistributionCell,
  UsageOverviewCell,
  TotalUsageCell,
  GradeDistributionCell,
  TrendingPLZCell,
  FederalStateDistributionCell,
} from "./Cells";

import styles from "./Statistics.module.scss";
import { ColumnLayout } from "../../Components/ColumnLayout";
import { LastDaysDownloadsCell } from "./Cells/LastDaysDownloadsCell";
import { LastDaysSessionsCell } from "./Cells/LastDaysSessionsCell";
import { StatisticsTabbar } from "./StatisticsTabbar";
import { selectStatisticsPage } from "../../Stores/ui";
import { useSelector } from "react-redux";
import { MarketingOverviewCell } from "./Cells/Marketing/MarketingOverviewCell";
import { LastDaysImpressionsCell } from "./Cells/Marketing/LastDaysImpressionsCell";
import { LastDaysClicksCell } from "./Cells/Marketing/LastDaysClicksCell";
import { NewUsersPerMonthCell } from "./Cells/NewUsersPerMonthCell";
import { TotalUsersPerMonthCell } from "./Cells/TotalUsersPerMonthCell";
import { MausPerMonthCell } from "./Cells/MausPerMonthCell";
import { AverageDAUsPerMonthCell } from "./Cells/AverageDAUsPerMonthCell";
import { ImpressionsPerMonthCell } from "./Cells/Marketing/ImpressionsPerMonthCell";
import { TotalSessionsPerMonthCell } from "./Cells/TotalSessionsPerMonthCell";

function Statistics() {
  const statisticsPage = useSelector(selectStatisticsPage);

  return (
    <div className={classNames(styles.dashboardContainer)}>
      <StatisticsTabbar />
      {statisticsPage === "GENERAL" ? (
        <ColumnLayout
          className={classNames(styles.statistics)}
          columns={[
            {
              cells: [
                <UsageOverviewCell />,
                <TotalUsageCell />,
                <LastDaysDownloadsCell />,
                <LastDaysSessionsCell />,
                <FederalStateDistributionCell />,
                <AverageDAUsPerMonthCell />,
              ],
            },
            {
              cells: [
                <SchoolTypeDistributionCell />,
                <OSDistributionCell />,
                <DeviceTypeDistributionCell />,
                <MausPerMonthCell />,
                <TotalSessionsPerMonthCell />,
              ],
            },
            {
              cells: [
                <GradeDistributionCell />,
                <TrendingPLZCell />,
                <NewUsersPerMonthCell />,
                <TotalUsersPerMonthCell />,
              ],
            },
          ]}
        />
      ) : (
        <ColumnLayout
          className={classNames(styles.statistics)}
          columns={[
            {
              cells: [<MarketingOverviewCell />],
            },
            {
              cells: [<LastDaysImpressionsCell />, <LastDaysClicksCell />],
            },
            {
              cells: [<ImpressionsPerMonthCell />],
            },
          ]}
        />
      )}
    </div>
  );
}

export { Statistics };
