import classNames from "classnames";
import styles from "./IconButton.module.scss";

interface IconButtonProps {
  className?: string;
  icon: string;
  onClick: () => void;
}

export const IconButton = ({ className, icon, onClick }: IconButtonProps) => {
  return (
    <button
      className={classNames(styles.iconButton, className)}
      onClick={onClick}
    >
      <img alt="Icon" src={icon} />
    </button>
  );
};
