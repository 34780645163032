import classNames from "classnames";
import React from "react";
import styles from "./Sidebar.module.scss";
import { SidebarButtonGroup } from "./SidebarButtonGroup";

import newSyncIcon from "../Assets/Icons/icon-new-sync.svg";
import settingsIcon from "../Assets/Icons/icon-settings.svg";
import logoutIcon from "../Assets/Icons/icon-logout.svg";

export interface SidebarProps {
  isCollapsed: boolean;
  onLogout: () => void;
  onForceNewReport: () => void;
  onOpenSettings: () => void;
}

const Sidebar: React.FunctionComponent<SidebarProps> = ({
  isCollapsed,
  onLogout,
  onForceNewReport,
  onOpenSettings,
  children,
}) => {
  return (
    <div
      className={classNames(styles.sidebar, {
        [styles.collapsed]: isCollapsed,
      })}
    >
      {children}

      <SidebarButtonGroup
        className={classNames(styles.bottomButtonGroup)}
        selectedItem={0}
        headingsAreVisible={!isCollapsed}
        buttons={[
          {
            title: "Neue Analyse anstoßen",
            icon: newSyncIcon,
            isActiveButton: false,
            isDestructive: false,
            type: "navigation",
            action: onForceNewReport,
          },
          {
            title: "Einstellungen",
            icon: settingsIcon,
            isActiveButton: false,
            isDestructive: false,
            type: "navigation",
            action: onOpenSettings,
          },
          {
            title: "Ausloggen",
            icon: logoutIcon,
            isActiveButton: false,
            isDestructive: false,
            type: "navigation",
            action: onLogout,
          },
        ]}
      />
    </div>
  );
};

export { Sidebar };
