import classNames from "classnames";
import styles from "./ActiveIndicator.module.scss";

interface ActiveIndicatorProps {
  active: boolean;
  className?: string;
}

export const ActiveIndicator = ({
  active,
  className,
}: ActiveIndicatorProps) => {
  return (
    <span
      className={classNames(
        styles.activeIndicator,
        {
          [styles.active]: active,
        },
        className
      )}
    ></span>
  );
};
