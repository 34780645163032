import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { CalloutHeading } from "./Controls/CalloutHeading";
import styles from "./SmallDataTable.module.scss";

interface SmallDataTableRowProps {
  title: string;
}

export const SmallDataTableRow = ({
  title,
  children,
}: PropsWithChildren<SmallDataTableRowProps>) => {
  return (
    <div className={styles.row}>
      <div>{title}</div>
      <div> {children}</div>
    </div>
  );
};

interface SmallDataTableSectionProps {
  title: string;
}

export const SmallDataTableSection = ({
  title,
  children,
}: PropsWithChildren<SmallDataTableSectionProps>) => {
  return (
    <div className={classNames(styles.section)}>
      <CalloutHeading>{title}</CalloutHeading>
      {children}
    </div>
  );
};

interface SmallDataTableProps {}

export const SmallDataTable = ({
  children,
}: PropsWithChildren<SmallDataTableProps>) => {
  return <div className={styles.dataTable}>{children}</div>;
};
