import classNames from "classnames";
import { PropsWithChildren } from "react";
import styles from "./Page.module.scss";

import plusIcon from "../Assets/Icons/icon-plus.svg";
import { PageContent } from "./PageContent";

interface PageProps {
  title: string;
  className?: string;
  onAddNew?: () => void;
}

export const Page = ({
  title,
  className,
  onAddNew,
  children,
}: PropsWithChildren<PageProps>) => {
  return (
    <div className={classNames(styles.page, className)}>
      <div className={styles.wrapper}>
        <h2>{title}</h2>
        {!!onAddNew && (
          <button className={styles.plusBackground} onClick={() => onAddNew()}>
            <img alt="Plus icon" src={plusIcon}></img>
          </button>
        )}
      </div>
      <PageContent mainView={<>{children}</>} />
    </div>
  );
};
