import classNames from "classnames";
import React from "react";

import styles from "./CellRow.module.scss";

export interface CellRowProps {
  title: string;
  subtitle?: string;
  rightElement?: React.ReactNode;

  boldTitle?: boolean;
}

const CellRow: React.FunctionComponent<CellRowProps> = ({
  title,
  subtitle,
  rightElement,
  boldTitle = false,
}) => {
  return (
    <div className={classNames(styles.cellRow, "noSelect")}>
      <div className={classNames(styles.titleContainer)}>
        <h3
          className={classNames(styles.heading, {
            [styles.boldHeading]: boldTitle,
          })}
        >
          {title}
        </h3>
        {subtitle !== undefined && (
          <p className={classNames(styles.subheading)}>{subtitle}</p>
        )}
      </div>

      <div className={classNames(styles.rightElement)}>{rightElement}</div>
    </div>
  );
};

export { CellRow };
