import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  ReferralEvent,
  ReferralEventStats,
  ReferralReport,
} from "@stududu-gmbh/stududu-api-definitions";
import { selectAuthentication } from "./company";

import { RootState, ThunkExtra } from "./rootStore";

export interface ReferralState {
  totalViews: number;
  totalStoreOpen: number;
  totalInstalls: number;
  referralEvents: ReferralEventStats[];
  referralEventsLoading: boolean;
}

export const initialState: ReferralState = {
  referralEvents: [],
  totalViews: 0,
  totalStoreOpen: 0,
  totalInstalls: 0,
  referralEventsLoading: false,
};

export const fetchReferrals = createAsyncThunk<
  ReferralReport | undefined,
  undefined,
  ThunkExtra
>(
  "referrals/fetchReferrals",
  async (_, { getState, extra: { api }, dispatch }) => {
    const { token } = selectAuthentication(getState() as RootState);

    if (!token) {
      return undefined;
    }

    return api.referralApi.fetchReferrals(token);
  }
);

export const createNewReferral = createAsyncThunk<
  ReferralEvent | undefined,
  string,
  ThunkExtra
>("referrals/createNewReferral", async (name, { getState, extra: { api } }) => {
  const { token } = selectAuthentication(getState() as RootState);

  if (!token) {
    return undefined;
  }

  return api.referralApi.createNewReferral(name, token);
});

export const deleteReferral = createAsyncThunk<
  { success: boolean } | undefined,
  ReferralEvent,
  ThunkExtra
>(
  "referrals/deleteReferral",
  async (referral, { getState, extra: { api } }) => {
    const { token } = selectAuthentication(getState() as RootState);

    if (!token) {
      return undefined;
    }

    return api.referralApi.deleteReferral(referral._id, token);
  }
);

export const referralSlice = createSlice({
  name: "referrals",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReferrals.pending, (state, action) => {
      state.referralEventsLoading = true;
    });
    builder.addCase(fetchReferrals.fulfilled, (state, action) => {
      state.referralEventsLoading = false;
      if (action.payload) {
        state.totalViews = action.payload.totalViews;
        state.totalStoreOpen = action.payload.totalStoreOpen;
        state.totalInstalls = action.payload.totalInstalls;
        state.referralEvents = action.payload.referrals;
      }
    });
    builder.addCase(fetchReferrals.rejected, (state) => {
      state.referralEventsLoading = false;
    });
    builder.addCase(createNewReferral.fulfilled, (state, action) => {
      if (action.payload) {
        state.referralEvents = state.referralEvents.concat([
          { ...action.payload, views: 0, storeOpens: 0, installs: 0 },
        ]);
      }
    });
    builder.addCase(deleteReferral.fulfilled, (state, action) => {
      if (action.payload && action.payload.success) {
        state.referralEvents = state.referralEvents.filter(
          (event) => event._id !== action.meta.arg._id
        );
      }
    });
  },
});

export const { reducer } = referralSlice;
export const actions = {
  ...referralSlice.actions,
  fetchReferrals,
};

export const selectReferralsStore = (state: RootState) => state.referrals;

export const selectReferralEvents = createSelector(
  selectReferralsStore,
  (store) => ({
    referralEvents: store?.referralEvents,
    referralEventsLoading: store?.referralEventsLoading,
  })
);

export const selectTotalStats = createSelector(
  selectReferralsStore,
  (store) => ({
    totalViews: store?.totalViews,
    totalStoreOpen: store?.totalStoreOpen,
    totalInstalls: store?.totalInstalls,
  })
);
