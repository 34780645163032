import classNames from "classnames";
import React, { useCallback } from "react";

import Tippy from "@tippyjs/react/headless";

import { GraphDataPoint } from "../Models";

import styles from "./DistributionBarChart.module.scss";

interface DistributionBarChartProps {
  elements: GraphDataPoint[];
}

function DistributionBarChart({ elements }: DistributionBarChartProps) {
  const sumOfAllElements = useCallback(() => {
    return elements.map((element) => element.value).reduce((a, b) => a + b, 0);
  }, [elements]);

  const getGridLayoutString = useCallback(() => {
    let columnString = "";
    for (const element of elements) {
      columnString += " ";
      columnString += `${(element.value / sumOfAllElements()) * 100}%`;
    }
    return columnString;
  }, [elements, sumOfAllElements]);

  return (
    <div
      className={classNames(styles.distributionBarChart)}
      style={{ gridTemplateColumns: getGridLayoutString() }}
    >
      {elements.map((element) => (
        <Tippy
          allowHTML={true}
          render={() => (
            <p className={"tooltip"}>
              {`${element.name}: ${element.value} | ${(
                (element.value / sumOfAllElements()) *
                100
              ).toFixed(2)}%`}
            </p>
          )}
        >
          <p
            style={{
              backgroundColor: element.color,
            }}
          ></p>
        </Tippy>
      ))}
    </div>
  );
}

export { DistributionBarChart };
