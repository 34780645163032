import styles from "./RadioItem.module.scss";

export interface RadioItemProps {
  label: string;
  value: any;
  checked: boolean;
  onCheck: (value?: any) => void;
}

export const RadioItem = ({
  label,
  value,
  checked,
  onCheck,
}: RadioItemProps) => {
  return (
    <div className={styles.radioItem}>
      <input
        type="radio"
        id={label.toUpperCase().replace(" ", "_")}
        value={value}
        checked={checked}
        onChange={(e) => {
          onCheck(e.target.value);
        }}
      />
      <label htmlFor={label.toUpperCase().replace(" ", "_")}>{label}</label>
    </div>
  );
};
