import classNames from "classnames";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import { Campaign } from "@stududu-gmbh/stududu-api-definitions";

import styles from "./CampaignCreationTarget.module.scss";
import generalStyles from "../../CampaignCreationForm.module.scss";
import { Grade, SchoolType } from "@stududu-gmbh/shared-interfaces";
import { FormHeading } from "../../../Controls/FormHeading";
import { Multiselect, MultiselectElement } from "../../../Controls/Multiselect";

interface CampaignCreationTargetProps {
  watch: UseFormWatch<Campaign>;
  setValue: UseFormSetValue<Campaign>;
}

export const CampaignCreationTarget = ({
  watch,
  setValue,
}: CampaignCreationTargetProps) => {
  const grades: MultiselectElement[] = [
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
    { label: "11", value: 11 },
    { label: "12", value: 12 },
    { label: "13", value: 13 },
  ];

  const schoolTypes = [
    { name: "Gymnasium", value: 0 },
    { name: "Realschule", value: 1 },
    { name: "Mittelschule", value: 2 },
    { name: "FOS", value: 3 },
    { name: "Wirtschaftsschule", value: 4 },
    { name: "Berufsschule", value: 5 },
    { name: "Grundschule", value: 6 },
    { name: "BOS", value: 7 },
    { name: "Montessori", value: 8 },
    { name: "Waldorf", value: 9 },
    { name: "Sonstige", value: 20 },
  ];

  return (
    <div className={styles.campaignCreationDuration}>
      <FormHeading bottomMargin="XS">Targeting</FormHeading>
      <div
        className={classNames(
          generalStyles.formLayout,
          styles.targetFormLayout
        )}
      >
        <div className={styles.grade}>
          <Multiselect
            elements={grades}
            onChange={(selectedElements) => {
              setValue("grades", selectedElements as Grade[], {
                shouldDirty: true,
              });
            }}
            selectedElements={watch().grades}
            title="Jahrgangsstufen"
          />
        </div>
        <div className={styles.schoolType}>
          <Multiselect
            elements={schoolTypes.map((s) => ({
              label: s.name,
              value: s.value,
            }))}
            onChange={(selectedElements) => {
              setValue("schoolTypes", selectedElements as SchoolType[], {
                shouldDirty: true,
              });
            }}
            selectedElements={watch().schoolTypes}
            title="Schultypen"
          />
        </div>
      </div>
    </div>
  );
};
