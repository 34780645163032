import classNames from "classnames";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import { Campaign } from "@stududu-gmbh/stududu-api-definitions";
import { TextField } from "../../../Controls/TextField";

import styles from "./CampaignCreationGeneral.module.scss";
import generalStyles from "../../CampaignCreationForm.module.scss";
import { FormHeading } from "../../../Controls/FormHeading";

interface CampaignCreationGeneralProps {
  watch: UseFormWatch<Campaign>;
  setValue: UseFormSetValue<Campaign>;
}

export const CampaignCreationGeneral = ({
  watch,
  setValue,
}: CampaignCreationGeneralProps) => {
  return (
    <>
      <FormHeading bottomMargin="XS">Allgemeines</FormHeading>
      <div
        className={classNames(
          generalStyles.formLayout,
          styles.generalFormLayout
        )}
      >
        <TextField
          title="Kampagnenname"
          value={watch().name}
          onValueChange={(value) => setValue("name", value)}
          className={styles.name}
        />
        <TextField
          title="Firma"
          className={styles.company}
          value={watch().company}
          onValueChange={(value) => setValue("company", value)}
        />
        <TextField
          title="Ziellink"
          className={styles.targetLink}
          value={watch().targetLink}
          onValueChange={(value) => setValue("targetLink", value)}
          placeholder="https://get.stududu.app"
        />
        <TextField
          title="Call to Action"
          className={styles.cta}
          value={watch().callToAction}
          onValueChange={(value) => setValue("callToAction", value)}
          placeholder="Jetzt bewerben"
        />
      </div>
    </>
  );
};
