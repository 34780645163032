import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import decode from "jwt-decode";

import { actions, selectAuthentication } from "../Stores/company";
import { tokenIsExpired } from "../Utils";

interface UseAuthenticationReturn {
  isAuthenticated: boolean;
  isLoading: boolean;
  userName: string | undefined;
  permissions: {};
}

export function useAuthentication(): UseAuthenticationReturn {
  const dispatch = useDispatch();
  const { token, authenticationLoading } = useSelector(selectAuthentication);
  const [userName, setUserName] = useState<string | undefined>(undefined);
  const [permissions, setPermissions] = useState<Object | undefined>(undefined);

  useEffect(() => {
    if (token) {
      const expired = tokenIsExpired(token);

      if (expired) {
        dispatch(actions.deleteTokenFromStorage());
      } else {
        const decoded = decode(token) as any;
        setUserName(decoded?.userName);
        setPermissions(decoded?.permissions);
      }
    }
  }, [token, setUserName, setPermissions, dispatch]);

  if (
    token === undefined ||
    userName === undefined ||
    permissions === undefined
  ) {
    return {
      isAuthenticated: false,
      isLoading: authenticationLoading,
      userName: undefined,
      permissions: {},
    };
  } else {
    return {
      isAuthenticated: true,
      isLoading: authenticationLoading,
      userName: userName,
      permissions: permissions,
    };
  }
}
