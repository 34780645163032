import { useCallback, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { AppHeaderBar } from "./Widgets/AppHeaderBar";
import { HomeSidebar } from "./Widgets/HomeSidebar";

import { useAuthentication } from "./Hooks/useAuthentication";
import { actions, selectAuthentication } from "./Stores/company";
import { fetchReport } from "./Stores/reports";
import { Referrals } from "./Pages/Login/Referrals";
import { Login } from "./Pages/Login/Login";
import { Dashboard } from "./Widgets/Dashboard/Dashboard";
import { Statistics } from "./Widgets/Statistics/Statistics";
import { CampaignCreation } from "./Widgets/CreateCampaign/CampaignCreation";

import styles from "./App.module.scss";
import { fetchCampaigns } from "./Stores/campaigns";
import { CampaignDetail } from "./Widgets/Campaigns/CampaignDetail";
import { tokenIsExpired } from "./Utils";
import { Campaigns } from "./Pages/Login/Campaigns";

function App() {
  const dispatch = useDispatch();
  const { token } = useSelector(selectAuthentication);
  const { isAuthenticated, isLoading } = useAuthentication();

  const checkToken = useCallback(() => {
    if (isLoading) {
      return;
    }

    const expired = tokenIsExpired(token);

    if (expired) {
      dispatch(actions.deleteTokenFromStorage());
    }
  }, [dispatch, isLoading, token]);

  const onFocus = useCallback(() => {
    checkToken();
  }, [checkToken]);

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, [onFocus]);

  useEffect(() => {
    dispatch(actions.loadTokenFromStorage());
    checkToken();
  }, [checkToken, dispatch]);

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      dispatch(fetchReport());
      dispatch(fetchCampaigns());
    }
  }, [isAuthenticated, isLoading, dispatch]);

  return (
    <div className={styles.App}>
      {!isAuthenticated && <Login />}
      {isAuthenticated && !isLoading && (
        <>
          <BrowserRouter>
            <AppHeaderBar />
            <HomeSidebar />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="statistics" element={<Statistics />} />
              <Route path="campaigns" element={<Campaigns />} />
              <Route path="referrals" element={<Referrals />} />
              <Route path="campaigns/:id" element={<CampaignDetail />} />
            </Routes>

            <CampaignCreation />
          </BrowserRouter>
        </>
      )}
    </div>
  );
}

export default App;
