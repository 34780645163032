import React from "react";

import styles from "./ActionBar.module.scss";
import { Button } from "./Button";
import chevron from "../../Assets/Icons/chevron-left.svg";

interface ActionBarProps {
  backTitle: string;
  onBackClick: () => void;
  onAbort?: () => void;
  onSave?: () => void;
}

export const ActionBar = ({
  backTitle,
  onBackClick,
  onAbort,
  onSave,
}: ActionBarProps) => {
  return (
    <div className={styles.bar}>
      <button className={styles.backButton} onClick={onBackClick}>
        <img alt="" src={chevron}></img>
        <p>{backTitle}</p>
      </button>
      <div className={styles.buttonContainer}>
        {onAbort && <Button title={"Abbrechen"} onClick={onAbort} />}
        {onSave && (
          <Button title={"Speichern"} onClick={onSave} type="submit" />
        )}
      </div>
    </div>
  );
};
