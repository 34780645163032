import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Report } from "@stududu-gmbh/stududu-api-definitions";

import { selectAuthentication } from "./company";

import { RootState, ThunkExtra } from "./rootStore";

export interface ReportsState {
  report: Report | undefined;
}

export const initialState: ReportsState = {
  report: undefined,
};

export const fetchReport = createAsyncThunk<
  Report | undefined,
  undefined,
  ThunkExtra
>("reports/fetchReport", async (_, { getState, extra: { api }, dispatch }) => {
  const { token } = selectAuthentication(getState() as RootState);

  if (!token) {
    return Promise.reject(undefined);
  }

  return await api.reportsApi.fetchCurrentReport(token);
});

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setCurrentReport(state, { payload }: PayloadAction<Report>) {
      state.report = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReport.fulfilled, (state, action) => {
      if (action.payload) {
        state.report = action.payload;
      }
    });
  },
});

export const { reducer } = reportsSlice;
export const actions = {
  ...reportsSlice.actions,
  fetchReport,
};

export const selectReportsStore = (state: RootState) => state.reports;

export const selectReport = createSelector(
  selectReportsStore,
  ({ report }) => report
);

export const selectMarketingReport = createSelector(
  selectReportsStore,
  ({ report }) => report?.reports.marketing
);
