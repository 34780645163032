import {
  ReferralEvent,
  ReferralReport,
} from "@stududu-gmbh/stududu-api-definitions";

export async function fetchReferrals(
  token: string
): Promise<ReferralReport | undefined> {
  const url = "/referral-service/api/v1/referral/report";

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/x-www-form-url-encoded",
      },
    });
    const data = (await response.json()) as {
      report: ReferralReport;
    };

    return data.report;
  } catch (error) {
    console.error(error);
  }
}

export async function createNewReferral(
  name: string,
  token: string
): Promise<ReferralEvent | undefined> {
  const url = "/referral-service/api/v1/referral/event";

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: name, type: "ORGANIZATION" }),
    });

    const referral = (await response.json()) as {
      data: ReferralEvent;
    };

    return referral.data;
  } catch (error) {
    console.error(error);
  }
}

export async function deleteReferral(
  referralEventID: string,
  token: string
): Promise<{ success: boolean } | undefined> {
  const url = `/referral-service/api/v1/referral/event/${referralEventID}`;

  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const responseJSON = (await response.json()) as {
      success: boolean;
    };

    return responseJSON;
  } catch (error) {
    console.error(error);
  }
}
