import classNames from "classnames";
import { PropsWithChildren } from "react";

import styles from "./Tabs.module.scss";

interface TabsProps {
  className?: string;
}

export function Tabs({className, children }: PropsWithChildren<TabsProps>) {
  return <div className={classNames(styles.tabs, className)}>{children}</div>;
}
