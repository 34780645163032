import React from "react";
import styles from "./SidebarButton.module.scss";

import classNames from "classnames";

export interface SidebarButtonProps {
  title: string;
  icon: string;
  isActiveButton: boolean;
  isDestructive: boolean;
  type: "navigation" | "action";
  action?: () => void;
  headingIsVisible?: boolean;
}

function SidebarButton({
  isActiveButton,
  title,
  icon,
  type = "navigation",
  isDestructive = false,
  action,
  headingIsVisible,
}: SidebarButtonProps) {
  return (
    <button
      className={classNames(styles.sidebarButton, {
        [styles.activeItem]: isActiveButton,
      })}
      onClick={() => action?.()}
    >
      <img className={classNames(styles.icon)} src={icon} alt=""></img>
      <p
        className={classNames(styles.heading, {
          [styles.isHidden]: !headingIsVisible,
        })}
      >
        {title}
      </p>
    </button>
  );
}

export { SidebarButton };
