import classNames from "classnames";
import { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import styles from "./FederalStateDistributionCell.module.scss";
import { selectReport } from "../../../Stores/reports";
import { GraphDataPoint } from "../../../Models";

import { BarGraph, DefaultCell } from "../../../Components";
import { ReportDataPoint } from "@stududu-gmbh/shared-interfaces";

function FederalStateDistributionCell() {
  const report = useSelector(selectReport);

  const [dataPoints, setDataPoints] = useState<GraphDataPoint[]>([]);

  const createDataPoint = useCallback(
    (federalState: ReportDataPoint): GraphDataPoint => {
      return {
        name: federalState.label ?? "",
        value: federalState.value ?? 0,
        color: "",
      };
    },
    []
  );

  useEffect(() => {
    setDataPoints(
      (report?.reports.general?.locations?.userPerFederalState ?? []).map(
        (federalState) => createDataPoint(federalState)
      )
    );
  }, [report, createDataPoint]);

  return (
    <DefaultCell title="Bundesländerverteilung">
      <div className={classNames(styles.chart)}>
        <BarGraph dataPoints={dataPoints} />
      </div>
    </DefaultCell>
  );
}

export { FederalStateDistributionCell };
