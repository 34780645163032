import classNames from "classnames";
import { PropsWithChildren } from "react";
import styles from "./FormHeading.module.scss";

interface FormHeadingProps {
  className?: string;
  bottomMargin?: "XXS" | "XS" | "S" | "M";
}

export const FormHeading = ({
  className,
  children,
  bottomMargin = "XXS",
}: PropsWithChildren<FormHeadingProps>) => {
  return (
    <h4
      className={classNames(styles.formHeading, className, {
        [styles.XXS]: bottomMargin === "XXS",
        [styles.XS]: bottomMargin === "XS",
        [styles.S]: bottomMargin === "S",
        [styles.M]: bottomMargin === "M",
      })}
    >
      {children}
    </h4>
  );
};
