import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

import { RootState, ThunkExtra } from "./rootStore";

export interface CompanyState {
  token: string | undefined;
  authenticationLoading: boolean;
  loginError?: "SERVER_ERROR" | "INVALID_CREDENTIALS";
}

export const initialState: CompanyState = {
  token: undefined,
  authenticationLoading: false,
  loginError: undefined,
};

export const authenticateUser = createAsyncThunk<
  string | undefined,
  { userName: string; password: string },
  ThunkExtra
>(
  "company/authenticateUser",
  async ({ userName, password }, { getState, extra: { api }, dispatch }) => {
    return api.authenticationApi.authenticateUser(userName, password);
  }
);

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    loadTokenFromStorage(state) {
      state.token = localStorage.getItem("token")?.toString() ?? undefined;
    },
    deleteTokenFromStorage(state) {
      state.token = undefined;
      localStorage.removeItem("token");
    },
    setToken(state, action: PayloadAction<string | undefined>) {
      if (action.payload) {
        state.token = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authenticateUser.pending, (state) => {
      state.authenticationLoading = true;
      state.loginError = undefined;
    });
    builder.addCase(authenticateUser.fulfilled, (state, action) => {
      if (action.payload) {
        state.token = action.payload;
        localStorage.setItem("token", action.payload);
        state.loginError = undefined;
      }
      state.authenticationLoading = false;
    });
    builder.addCase(authenticateUser.rejected, (state, action) => {
      state.loginError = action.error.message as
        | "SERVER_ERROR"
        | "INVALID_CREDENTIALS";
      state.authenticationLoading = false;
    });
  },
});

export const { reducer } = companySlice;
export const actions = companySlice.actions;

export const selectCompanyStore = (state: RootState) => state.company;

export const selectAuthentication = createSelector(
  selectCompanyStore,
  ({ token, authenticationLoading }) => ({
    token,
    authenticationLoading,
  })
);

export const selectLoginError = createSelector(
  selectCompanyStore,
  ({ loginError }) => ({
    loginError,
  })
);
