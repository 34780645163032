import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab } from "../../Components/Tab";
import { Tabs } from "../../Components/Tabs";
import { actions, selectStatisticsPage } from "../../Stores/ui";
import styles from "./StatisticsTabbar.module.scss";

export function StatisticsTabbar() {
  const dispatch = useDispatch();

  const selectedStatisticsPage = useSelector(selectStatisticsPage);

  const handleGeneralSelect = useCallback(() => {
    dispatch(actions.setStatisticsPage("GENERAL"));
  }, [dispatch]);

  const handleMarketingSelect = useCallback(() => {
    dispatch(actions.setStatisticsPage("MARKETING"));
  }, [dispatch]);

  return (
    <div className={styles.statisticsTabbar}>
      <Tabs>
        <Tab
          title="Allgemein"
          selected={selectedStatisticsPage === "GENERAL"}
          onClick={handleGeneralSelect}
        />
        <Tab
          title="Werbung"
          selected={selectedStatisticsPage === "MARKETING"}
          onClick={handleMarketingSelect}
        />
      </Tabs>
    </div>
  );
}
