import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import styles from "./TrendingPLZCell.module.scss";
import { selectReport } from "../../../Stores/reports";

import { DefaultCell } from "../../../Components/DefaultCell";
import { CellRow } from "../../../Components/CellRow";

const TrendingPLZCell: React.FunctionComponent = () => {
  const report = useSelector(selectReport);

  return (
    <DefaultCell title="Trendende Postleitzahlen" subtitle="in den letzten 24h">
      <div className={classNames("rowContainer")}>
        {report?.reports.general?.locations?.userPerZIP
          ?.slice(0, 10)
          .map((plzRegion) => (
            <CellRow
              title={plzRegion.label ?? ""}
              boldTitle={false}
              rightElement={
                <p className={classNames(styles.countLabel)}>
                  {plzRegion.value}&#x2009;&#xd7;
                </p>
              }
              key={plzRegion.plz}
            />
          ))}
      </div>
    </DefaultCell>
  );
};

export { TrendingPLZCell };
