import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "./rootStore";

export type SelectedPage =
  | "dashboard"
  | "statistics"
  | "campaigns"
  | "referrals"
  | "target-analysis";

type StatisticsPage = "GENERAL" | "MARKETING";

export interface UIState {
  selectedPage: SelectedPage;
  sidebarCollapsed: boolean;
  campaignCreationOpen: boolean;
  referralCreationOpen: boolean;
  statistics: {
    page: StatisticsPage;
  };
}

export const initialState: UIState = {
  selectedPage: "dashboard",
  sidebarCollapsed: true,
  campaignCreationOpen: false,
  referralCreationOpen: false,
  statistics: {
    page: "GENERAL",
  },
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setNewPage(state, { payload }: PayloadAction<SelectedPage>) {
      state.selectedPage = payload;
    },
    toggleSidebarCollapsed(state) {
      state.sidebarCollapsed = !state.sidebarCollapsed;
    },
    openCampaignCreation(state) {
      state.campaignCreationOpen = true;
    },
    closeCampaignCreation(state) {
      state.campaignCreationOpen = false;
    },

    openReferralCreation(state) {
      state.referralCreationOpen = true;
    },
    closeReferralCreation(state) {
      state.referralCreationOpen = false;
    },

    setStatisticsPage(state, { payload }: PayloadAction<StatisticsPage>) {
      state.statistics.page = payload;
    },
  },
});

export const { reducer } = uiSlice;
export const actions = uiSlice.actions;

export const selectUIStore = (state: RootState) => state.ui;

export const selectSelectedPage = createSelector(
  selectUIStore,
  ({ selectedPage }) => selectedPage
);

export const selectSidebarCollapsed = createSelector(
  selectUIStore,
  ({ sidebarCollapsed }) => sidebarCollapsed
);

export const selectCampaignCreationOpen = createSelector(
  selectUIStore,
  ({ campaignCreationOpen }) => campaignCreationOpen
);

export const selectReferralCreationOpen = createSelector(
  selectUIStore,
  ({ referralCreationOpen }) => referralCreationOpen
);


export const selectStatisticsPage = createSelector(
  selectUIStore,
  ({ statistics }) => statistics.page
);
