import React from "react";

import styles from "./DashboardUsageCell.module.scss";

import classNames from "classnames";

import { useSelector } from "react-redux";
import { selectReport } from "../../../Stores/reports";

import { DefaultCell } from "../../../Components/DefaultCell";
import { CellBubble } from "../../../Components/CellBubble";

const DashboardUsageCell: React.FunctionComponent = () => {
  const report = useSelector(selectReport);

  return (
    <DefaultCell title="Nutzung" subtitle="in den letzten 24h">
      <div className={classNames("bubbleContainer")}>
        <CellBubble
          info="Neue Nutzer"
          value={String(report?.reports.general?.users?.new ?? 0)}
        ></CellBubble>
        <CellBubble
          info="Sessions"
          value={String(report?.reports.general?.sessions?.new ?? 0)}
        ></CellBubble>
        <CellBubble info="Wiederkehrend" value={"-"}></CellBubble>
        <CellBubble
          info="Zuwachs"
          value={report?.reports.general?.users?.growth ?? "-"}
        ></CellBubble>
      </div>
    </DefaultCell>
  );
};

export { DashboardUsageCell };
