import { useSelector } from "react-redux";
import classNames from "classnames";

import { selectReport } from "../../../Stores/reports";
import { getNumerStringWithPoints, getPercentageString } from "../../../Utils";
import styles from "./UsageOverviewCell.module.scss";

import { DefaultCell, CellBubble } from "../../../Components";

function UsageOverviewCell() {
  const report = useSelector(selectReport);

  return (
    <DefaultCell title="Nutzung" subtitle="in den letzten 24h">
      <div className={classNames("bubbleContainer")}>
        <CellBubble
          info="Neue User"
          value={getNumerStringWithPoints(
            report?.reports.general?.users?.new,
            "-"
          )}
        ></CellBubble>
        <CellBubble
          info="Sessions"
          value={getNumerStringWithPoints(
            report?.reports.general?.sessions?.new,
            "-"
          )}
        ></CellBubble>
        <CellBubble
          info="Aktive User in %"
          value={
            report?.reports.general?.users?.total !== 0 &&
            report?.reports.general?.users?.total !== undefined
              ? getPercentageString(
                  (report?.reports.general?.users?.active ?? 0) /
                    report?.reports.general?.users?.total
                )
              : "-"
          }
        ></CellBubble>
        <CellBubble
          info="Zuwachs"
          value={report?.reports.general?.users?.growth ?? "-"}
        ></CellBubble>
      </div>
    </DefaultCell>
  );
}

export { UsageOverviewCell };
