import { useState, useEffect } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import styles from "./OSDistributionCell.module.scss";
import { selectReport } from "../../../Stores/reports";
import { GraphDataPoint } from "../../../Models";

import {
  DefaultCell,
  ChartLegend,
  DistributionBarChart,
} from "../../../Components";

function OSDistributionCell() {
  const report = useSelector(selectReport);

  const [dataPoints, setDataPoints] = useState<GraphDataPoint[]>([]);

  useEffect(() => {
    let presavedDataPoints: GraphDataPoint[] = [];

    if (report?.reports.general?.devices?.os?.android) {
      presavedDataPoints.push({
        name: "Android",
        value: report?.reports.general?.devices?.os?.android ?? 0,
        color: "#27AE60",
      });
    }
    if (report?.reports.general?.devices?.os?.ios) {
      presavedDataPoints.push({
        name: "iOS",
        value: report?.reports.general?.devices?.os?.ios ?? 0,
        color: "#2980B9",
      });
    }
    setDataPoints(presavedDataPoints);
  }, [report]);

  return (
    <DefaultCell title="Betriebssystem">
      <div className={classNames(styles.chart)}>
        <DistributionBarChart elements={dataPoints} />
        <ChartLegend elements={dataPoints} />
      </div>
    </DefaultCell>
  );
}

export { OSDistributionCell };
