import styles from "./ErrorInfo.module.scss";

import errorIcon from "../Assets/Icons/icon-error.svg";
import classNames from "classnames";

export function ErrorInfo({ text }: { text: string }) {
  return (
    <div className={classNames(styles.errorInfo, "noSelect")}>
      <div className={styles.centerContainer}>
        <img src={errorIcon} alt=""></img>
      </div>
      <p className={styles.text}>{text}</p>
    </div>
  );
}
