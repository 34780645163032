import { useCallback, useRef, useState } from "react";
import { IconButton } from "./Controls/IconButton";
import styles from "./Modal.module.scss";

import close from "../Assets/Icons/close.svg";
import { Button } from "./Controls/Button";
import classNames from "classnames";

interface ModalProps {
  isOpen: boolean;
  title?: string;
  content: React.ReactElement;
  onClose: () => void;
  onConfirm: () => void;
}

export const Modal = ({
  isOpen,
  title,
  onClose,
  onConfirm,
  content,
}: ModalProps) => {
  const background = useRef<HTMLDivElement | null>(null);

  const [scrolledUnderNav, setScrolledUnderNav] = useState(false);

  const handleBackgroundClick = useCallback(
    (e) => {
      if (e.target === background.current) {
        onClose?.();
      }
    },
    [onClose]
  );

  return (
    <div
      className={classNames(styles.background, { [styles.isOpen]: isOpen })}
      onClick={handleBackgroundClick}
      ref={background}
    >
      <div
        className={classNames(styles.modal, {
          [styles.isOpen]: isOpen,
          [styles.navWithBorder]: scrolledUnderNav,
        })}
      >
        <div className={styles.topContent}>
          <div className={styles.closeIcon}>
            <IconButton icon={close} onClick={onClose} />
          </div>

          <h2>{title}</h2>
        </div>
        <div
          className={styles.scrollingContent}
          onScroll={(e) => setScrolledUnderNav(e.currentTarget.scrollTop > 30)}
        >
          {content}
        </div>
        <div className={styles.bottomContent}>
          <div className={styles.buttonContainer}>
            <Button title="Abbrechen" color="grey" onClick={() => onClose()} />
            <Button title="Speichern" onClick={() => onConfirm()} />
          </div>
        </div>
      </div>
    </div>
  );
};
