import {
  Campaign,
  CreationCampaign,
  CampaignResponse,
  CampaignsResponse,
} from "@stududu-gmbh/stududu-api-definitions";

export async function postCampaign(
  campaign: CreationCampaign,
  token: string
): Promise<Campaign | undefined> {
  const url = "/marketing-service/api/v1/campaigns";

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(campaign),
    });

    const campaingResponse = (await response.json()) as CampaignResponse;
    return campaingResponse.data;
  } catch (error) {
    console.error(error);
  }
}

export async function fetchCampaigns(token: string): Promise<Campaign[]> {
  const url = "/marketing-service/api/v1/campaigns";

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/x-www-form-url-encoded",
      Authorization: `Bearer ${token}`,
    },
  });

  const campaingResponse = (await response.json()) as CampaignsResponse;
  return campaingResponse.data ?? [];
}

export async function fetchCampaign(
  id: string,
  token: string
): Promise<Campaign> {
  const url = `/marketing-service/api/v1/campaigns/${id}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/x-www-form-url-encoded",
      Authorization: `Bearer ${token}`,
    },
  });

  const campaingResponse = (await response.json()) as CampaignResponse;
  const campaign: Campaign = campaingResponse?.data;

  if (!response.ok || !campaign) {
    return Promise.reject();
  }

  return campaign;
}

export async function updateCampaign(
  campaign: Campaign,
  token: string
): Promise<Campaign | undefined> {
  const url = `/marketing-service/api/v1/campaigns/${campaign._id}`;

  const postCampaign: CreationCampaign = {
    name: campaign.name,
    company: campaign.company,
    targetLink: campaign.targetLink,
    callToAction: campaign.callToAction,
    campaignPaymentType: campaign.campaignPaymentType,
    duration: campaign.duration,
    prepaidImpressions: campaign.prepaidImpressions,
    grades: campaign.grades,
    schoolTypes: campaign.schoolTypes,
  };

  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postCampaign),
    });

    const campaingResponse = (await response.json()) as CampaignResponse;
    return campaingResponse.data;
  } catch (error) {
    console.error(error);
  }
}

export async function updateCampaignStatus(
  campaignID: string,
  active: boolean,
  token: string
): Promise<void> {
  const url = `/marketing-service/api/v1/campaigns/${campaignID}/status`;

  try {
    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ active }),
    });

    if (!response.ok) {
      return Promise.reject();
    }
  } catch (error) {
    console.error(error);
  }
}

export async function deleteCampaign(
  campaignID: string,
  token: string
): Promise<void> {
  const url = `/marketing-service/api/v1/campaigns/${campaignID}`;

  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      Promise.resolve();
    }
  } catch (error) {
    console.error(error);
  }
}
