import {
  SmallDataTable,
  SmallDataTableRow,
  SmallDataTableSection,
} from "../SmallDataTable";

import greenCheck from "../../Assets/Icons/green-check.svg";
import redCheck from "../../Assets/Icons/red-check.svg";
import { useImageExists } from "../../Hooks/useImageExists";
import { Tooltip } from "../Controls/Tooltip";
import { Toggle } from "../Controls/Toggle";
import { Campaign } from "@stududu-gmbh/stududu-api-definitions";
import { useSelector } from "react-redux";
import { selectActiveCampaign } from "../../Stores/campaigns";
import { selectMarketingReport } from "../../Stores/reports";

interface CampaignDataTableProps {
  campaign?: Campaign;
  onActiveToggle: () => void;
}

export const CampaignDataTable = ({
  campaign,
  onActiveToggle,
}: CampaignDataTableProps) => {
  const { imageExists } = useImageExists(
    `https://banner-ad.stududu.de/${campaign?._id}/billboard.jpg`
  );

  const report = useSelector(selectMarketingReport);
  const { activeCampaign } = useSelector(selectActiveCampaign);

  return (
    <>
      {!!campaign && (
        <SmallDataTable>
          <SmallDataTableSection title="Allgemein">
            <SmallDataTableRow title={"Status"}>
              {<Toggle active={campaign.active} onToggle={onActiveToggle} />}
            </SmallDataTableRow>
          </SmallDataTableSection>
          <SmallDataTableSection title="Zahlung">
            <SmallDataTableRow title={"Methode"}>
              {campaign.campaignPaymentType === "ABO" ? "Abo" : "Prepaid"}
            </SmallDataTableRow>
            {campaign.campaignPaymentType === "ABO" && (
              <SmallDataTableRow title={"Ablaufdatum"}>
                {campaign.endDate}
              </SmallDataTableRow>
            )}
          </SmallDataTableSection>
          <SmallDataTableSection title="Medien">
            <SmallDataTableRow title={"Ordnername"}>
              {campaign._id}
            </SmallDataTableRow>
            <SmallDataTableRow title={"Dateiname"}>
              billboard.jpg
            </SmallDataTableRow>
            <SmallDataTableRow title={"Status"}>
              <Tooltip
                text={
                  imageExists
                    ? "Die Bilddatei ist korrekt hinterlegt"
                    : "Es ist keine Bilddatei hinterlegt"
                }
              >
                <img alt="" src={imageExists ? greenCheck : redCheck} />
              </Tooltip>
            </SmallDataTableRow>
          </SmallDataTableSection>
          <SmallDataTableSection title="Statistiken">
            <SmallDataTableRow title={"Impressions"}>
              {
                report?.campaigns.campaigns.find(
                  (c) => c.campaignID === activeCampaign?._id
                )?.impressions
              }
            </SmallDataTableRow>
            <SmallDataTableRow title={"Klicks"}>
              {
                report?.campaigns.campaigns.find(
                  (c) => c.campaignID === activeCampaign?._id
                )?.clicks
              }
            </SmallDataTableRow>
          </SmallDataTableSection>
        </SmallDataTable>
      )}
    </>
  );
};
