import classNames from "classnames";
import styles from "./Tab.module.scss";

export interface TabProps {
  title: string;
  selected?: boolean;
  onClick?: () => void;
}

export function Tab({ title, selected = false, onClick }: TabProps) {
  return (
    <button
      className={classNames(styles.tab, { [styles.selected]: selected })}
      onClick={(e) => {
        e.preventDefault();
        onClick?.();
      }}
    >
      {title}
    </button>
  );
}
