import classNames from "classnames";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ActiveIndicator } from "../../Components/ActiveIndicator";

import { OptionalImage } from "../../Components/OptionalImage";
import { Page } from "../../Components/Page";
import { fetchCampaigns, selectCampaigns } from "../../Stores/campaigns";
import { selectMarketingReport } from "../../Stores/reports";
import { actions } from "../../Stores/ui";
import { CampaignCreation } from "../../Widgets/CreateCampaign/CampaignCreation";

import styles from "./Campaigns.module.scss";

export const Campaigns = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { campaigns, campaignsLoading } = useSelector(selectCampaigns);

  const report = useSelector(selectMarketingReport);

  useEffect(() => {
    dispatch(fetchCampaigns());
  }, [dispatch]);

  useEffect(() => {
    document.title = "Kampagnen | Übersicht";
  }, []);

  const handleCampaignCreationOpen = useCallback(() => {
    dispatch(actions.openCampaignCreation());
  }, [dispatch]);

  return (
    <>
      <Page title="Kampagnen" onAddNew={handleCampaignCreationOpen}>
        <div className={classNames(styles.table)}>
          <div className={styles.tableHeader}>
            <th className={styles.activeColumn}></th>
            <th className={styles.campaignColoumn}></th>
            <th className={styles.defaultColoumn}>Modell</th>
            <th className={styles.defaultColoumn}>Impressions</th>
            <th className={styles.defaultColoumn}>Klicks</th>
          </div>
          {campaigns.length > 0 &&
            !campaignsLoading &&
            campaigns.map((campaign) => (
              <div
                className={classNames(styles.tableRow)}
                key={campaign._id}
                onClick={() => navigate(`/campaigns/${campaign._id}`)}
              >
                <div className={classNames(styles.tableCell)}>
                  <ActiveIndicator
                    active={campaign.active}
                    className={styles.activeIndicator}
                  />
                </div>
                <div
                  className={classNames(styles.tableCell, styles.campaignCell)}
                >
                  <OptionalImage
                    url={`https://banner-ad.stududu.de/${campaign._id}/billboard.jpg`}
                    className={classNames(styles.previewImage)}
                  />

                  <div>
                    <strong>{campaign.name}</strong>
                    <p>{campaign.company}</p>
                  </div>
                </div>

                <div className={classNames(styles.tableCell)}>
                  {campaign.campaignPaymentType === "ABO" ? "Abo" : "Prepaid"}
                </div>
                <div className={classNames(styles.tableCell)}>
                  {
                    report?.campaigns.campaigns.find(
                      (c) => c.campaignID === campaign._id
                    )?.impressions
                  }
                </div>
                <div className={classNames(styles.tableCell)}>
                  {
                    report?.campaigns.campaigns.find(
                      (c) => c.campaignID === campaign._id
                    )?.clicks
                  }
                </div>
              </div>
            ))}
        </div>
      </Page>

      <CampaignCreation />
    </>
  );
};
