import { useSelector } from "react-redux";
import classNames from "classnames";

import { selectReport } from "../../../../Stores/reports";
import {
  getNumerStringWithPoints,
  getShortNumberString,
} from "../../../../Utils";
import styles from "../UsageOverviewCell.module.scss";

import { DefaultCell, CellBubble } from "../../../../Components";

export function MarketingOverviewCell() {
  const report = useSelector(selectReport);

  return (
    <DefaultCell title="Übersicht">
      <div className={classNames("bubbleContainer")}>
        <CellBubble
          info="Aktive Ads"
          value={getNumerStringWithPoints(
            report?.reports.marketing?.campaigns.active,
            "-"
          )}
        />
        <CellBubble
          info="Ads"
          value={getNumerStringWithPoints(
            report?.reports.marketing?.campaigns.total,
            "-"
          )}
        />
        <CellBubble
          info="Impr. per Sess."
          value={getShortNumberString(
            (report?.reports.marketing?.impressions.reduce(
              (pv, cv) => pv + (cv?.value ?? 0),
              0
            ) ?? 0) /
              (report?.reports.general?.sessions.perDay.reduce(
                (pv, cv) => pv + (cv?.value ?? 0),
                0
              ) ?? 1)
          )}
        />
      </div>
    </DefaultCell>
  );
}
