import jwt_decode from "jwt-decode";

export const tokenIsExpired = (token?: string) => {
  if (!token) {
    return false;
  }

  let decodedToken: { exp: number; iat: number } = jwt_decode(token);

  if (!decodedToken?.exp) {
    return false;
  }

  var dateNow = new Date();

  return decodedToken.exp < dateNow.getTime() / 1000;
};
