import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import styles from "./PageContent.module.scss";

interface PageContentProps {
  mainView?: React.ReactChild;
  sideView?: React.ReactChild;
}

export const PageContent = ({
  mainView,
  sideView,
}: PropsWithChildren<PageContentProps>) => {
  return (
    <div className={styles.pageContent}>
      <div className={classNames(styles.main)}>{!!mainView && mainView}</div>
      <div className={classNames(styles.side)}> {!!sideView && sideView}</div>
    </div>
  );
};
