import classNames from "classnames";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import { Campaign } from "@stududu-gmbh/stududu-api-definitions";

import styles from "./CampaignCreationDuration.module.scss";
import generalStyles from "../../CampaignCreationForm.module.scss";
import { RadioGroup } from "../../../Controls/RadioGroup";
import {
  CampaignDuration,
  CampaignPaymentType,
} from "@stududu-gmbh/shared-interfaces";
import { Dropdown } from "../../../Controls/Dropdown";
import { FormHeading } from "../../../Controls/FormHeading";

interface CampaignCreationDurationProps {
  watch: UseFormWatch<Campaign>;
  setValue: UseFormSetValue<Campaign>;
}

export const CampaignCreationDuration = ({
  watch,
  setValue,
}: CampaignCreationDurationProps) => {
  return (
    <div className={styles.campaignCreationDuration}>
      <FormHeading bottomMargin="XS">Laufzeit</FormHeading>
      <div
        className={classNames(generalStyles.formLayout, {
          [styles.durationFormLayout]: watch().campaignPaymentType === "ABO",
          [styles.prepaidFormLayout]: watch().campaignPaymentType === "PREPAID",
        })}
      >
        <RadioGroup
          currentValue={watch().campaignPaymentType}
          items={[
            { label: "Prepaid", value: "PREPAID" },
            { label: "Abo", value: "ABO" },
          ]}
          onValueChange={(selection) => {
            const paymentType = selection as CampaignPaymentType;
            if (paymentType) {
              setValue("campaignPaymentType", paymentType);
            }
          }}
          wrap={false}
        />

        {watch().campaignPaymentType === "ABO" ? (
          <Dropdown
            className={styles.duration}
            elements={[
              { label: "1 Woche", value: "ONE_WEEK" },
              { label: "1 Monat", value: "ONE_MONTH" },
              { label: "3 Monate", value: "THREE_MONTHS" },
              { label: "6 Monate", value: "SIX_MONTHS" },
              { label: "1 Jahr", value: "ONE_YEAR" },
              { label: "Unendlich", value: "INFINITY" },
            ]}
            selectedElement={watch().duration}
            onChange={(selection) => {
              const duration = selection as CampaignDuration;
              if (duration) {
                setValue("duration", duration);
              }
            }}
            zIndex={110}
          />
        ) : undefined}
        {watch().campaignPaymentType === "PREPAID" ? (
          <Dropdown
            className={styles.prepaid}
            elements={[
              { label: "1.000", value: 1000 },
              { label: "5.000", value: 5000 },
              { label: "10.000", value: 10000 },
              { label: "20.000", value: 20000 },
              { label: "50.000", value: 50000 },
            ]}
            selectedElement={watch().prepaidImpressions}
            onChange={(selection) => {
              const impressions = selection as number;
              if (impressions) {
                setValue("prepaidImpressions", impressions);
              }
            }}
            zIndex={110}
          />
        ) : undefined}
      </div>
    </div>
  );
};
