import React, { useCallback } from "react";

import Tippy from "@tippyjs/react/headless";

import styles from "./BarGraph.module.scss";
import classNames from "classnames";
import { getPercentageString } from "../Utils";

export interface BarGraphDataPoint {
  name: string;
  value: number;
}

interface BarGraphProps {
  displaysInPercent?: boolean;
  dataPoints: BarGraphDataPoint[];
}

function BarGraph({ displaysInPercent = true, dataPoints }: BarGraphProps) {
  const colors = [
    "#27AE60",
    "#2980B9",
    "#8E44AD",
    "#E74C3C",
    "#E67E22",
    "#3498DB",
    "#9B59B6",
    "#F1C40F",
    "#2ECC71",
  ];

  const maxValue = useCallback(() => {
    return Math.max(...dataPoints.map((point) => point.value));
  }, [dataPoints]);

  const sumOfAllElements = useCallback(() => {
    return dataPoints.map((point) => point.value).reduce((a, b) => a + b, 0);
  }, [dataPoints]);

  return (
    <div className={styles.barGraph}>
      {dataPoints
        .sort((a, b) => b.value - a.value)
        .map((dataPoint) => (
          <div className={classNames(styles.datapointRow)}>
            <p className={classNames(styles.datapointName)}>{dataPoint.name}</p>
            <Tippy
              allowHTML={true}
              render={() => (
                <p className={"tooltip"}>
                  {`${dataPoint.name}: ${dataPoint.value} | ${(
                    (dataPoint.value / sumOfAllElements()) *
                    100
                  ).toFixed(2)}%`}
                </p>
              )}
            >
              <div className={classNames(styles.barContainer)}>
                <div
                  className={classNames(styles.bar)}
                  style={{
                    width: `${(dataPoint.value / maxValue()) * 100}%`,
                    backgroundColor:
                      colors[dataPoints.indexOf(dataPoint) % colors.length],
                  }}
                />
              </div>
            </Tippy>
            <p className={classNames(styles.valueLabel)}>
              {displaysInPercent
                ? getPercentageString(dataPoint.value / sumOfAllElements())
                : dataPoint.value}
            </p>
          </div>
        ))}
    </div>
  );
}

export { BarGraph };
