import styles from "./LoadingSpinner.module.scss";

export function LoadingSpinner() {
  return (
    <div className={styles.loadingSpinner}>
      <div className={styles.dot} style={{ animationDelay: "0" }} />
      <div className={styles.dot} style={{ animationDelay: "0.1s" }} />
      <div className={styles.dot} style={{ animationDelay: "0.2s" }} />
    </div>
  );
}
