import classNames from "classnames";
import { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { GraphDataPoint } from "../../../Models";
import { selectReport } from "../../../Stores/reports";
import styles from "./GradeDistributionCell.module.scss";

import { DefaultCell, BarGraph } from "../../../Components";
import { ReportDataPoint } from "@stududu-gmbh/shared-interfaces";

function GradeDistributionCell() {
  const report = useSelector(selectReport);

  const [dataPoints, setDataPoints] = useState<GraphDataPoint[]>([]);

  const createDataPoint = useCallback(
    (grade: ReportDataPoint): GraphDataPoint => {
      return {
        name: grade.label !== "" ? grade.label ?? "" : "Keine Angabe",
        value: grade.value ?? 0,
        color: "",
      };
    },
    []
  );

  useEffect(() => {
    setDataPoints(
      (report?.reports.general?.targetGroups?.grades ?? []).map((grade) =>
        createDataPoint(grade)
      )
    );
  }, [report, createDataPoint]);

  return (
    <DefaultCell title="Jahrgangsstufe">
      <div className={classNames(styles.chart)}>
        <BarGraph dataPoints={dataPoints} />
      </div>
    </DefaultCell>
  );
}

export { GradeDistributionCell };
