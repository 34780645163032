export const checkIfImageExists = async (
  image_url: string
): Promise<boolean> => {
  try {
    const response = await fetch(image_url, {
      method: "GET",
      headers: { "Content-Type": "application/image" },
    });
    return response.status !== 404;
  } catch {
    return false;
  }
};
