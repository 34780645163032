import { useSelector } from "react-redux";
import classNames from "classnames";
import { selectReport } from "../../../Stores/reports";

import styles from "./TotalUsageCell.module.scss";

import { DefaultCell, CellBubble } from "../../../Components";
import { getNumerStringWithPoints } from "../../../Utils";

function TotalUsageCell() {
  const report = useSelector(selectReport);

  return (
    <DefaultCell title="Nutzung" subtitle="Gesamt">
      <div className={classNames("bubbleContainer")}>
        <CellBubble
          info="User"
          value={getNumerStringWithPoints(
            report?.reports.general?.users?.total,
            "-"
          )}
        ></CellBubble>
        <CellBubble
          info="Sessions"
          value={getNumerStringWithPoints(
            report?.reports.general?.sessions?.total,
            "-"
          )}
        ></CellBubble>
      </div>
    </DefaultCell>
  );
}

export { TotalUsageCell };
