import classNames from "classnames";

import { GraphDataPoint } from "../Models";

import styles from "./ChartLegend.module.scss";

export interface ChartLegendProps {
  elements: GraphDataPoint[];
}

function ChartLegend({ elements }: ChartLegendProps) {
  return (
    <div className={classNames(styles.chartLegend)}>
      {elements.map((element) => (
        <div key={element.name} className={classNames(styles.elementContainer)}>
          <div
            className={classNames(styles.colorDot)}
            style={{ backgroundColor: element.color }}
          ></div>
          <p>{element.name}</p>
        </div>
      ))}
    </div>
  );
}

export { ChartLegend };
