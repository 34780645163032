import classNames from "classnames";
import React from "react";

import styles from "./CellBubble.module.scss";

export interface CellRowProps {
  value: string;
  info?: string;
}

const CellBubble: React.FunctionComponent<CellRowProps> = ({ value, info }) => {
  return (
    <div className={classNames(styles.cellBubble, "noSelect")}>
      <p className={classNames(styles.info)}>{info}</p>
      <h3 className={classNames(styles.value)}>{value}</h3>
    </div>
  );
};

export { CellBubble };
