import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  Campaign,
  CreationCampaign,
} from "@stududu-gmbh/stududu-api-definitions";
import { selectAuthentication } from "./company";

import { RootState, ThunkExtra } from "./rootStore";

export interface CampaignsState {
  campaigns: Campaign[];
  campaignsLoading: boolean;
  activeCampaign?: string;
}

export const initialState: CampaignsState = {
  campaigns: [],
  campaignsLoading: false,
  activeCampaign: undefined,
};

export const postCampaign = createAsyncThunk<
  Campaign | undefined,
  CreationCampaign,
  ThunkExtra
>(
  "campaigns/postCampaign",
  async (campaign, { getState, extra: { api }, dispatch }) => {
    const { token } = selectAuthentication(getState() as RootState);

    if (!token || !campaign) {
      return Promise.resolve(undefined);
    }

    return api.marketingApi.postCampaign(campaign, token);
  }
);

export const fetchCampaigns = createAsyncThunk<
  Campaign[] | undefined,
  undefined,
  ThunkExtra
>(
  "campaigns/fetchCampaigns",
  async (_, { getState, extra: { api }, dispatch }) => {
    const { token } = selectAuthentication(getState() as RootState);
    if (!token) {
      console.error("ERROR: No token provided");
      return Promise.resolve(undefined);
    }

    return api.marketingApi.fetchCampaigns(token);
  }
);

export const deleteCampaign = createAsyncThunk<{}, Campaign, ThunkExtra>(
  "campaigns/fetchCampaigns",
  async (campaign, { getState, extra: { api }, dispatch }) => {
    const { token } = selectAuthentication(getState() as RootState);
    if (!token) {
      console.error("ERROR: No token provided");
      return Promise.resolve(undefined);
    }

    return api.marketingApi.deleteCampaign(campaign._id, token);
  }
);

export const fetchCampaignDetail = createAsyncThunk<
  Campaign,
  string,
  ThunkExtra
>(
  "campaigns/fetchCampaignDetail",
  async (campaignId, { getState, extra: { api }, dispatch }) => {
    const { token } = selectAuthentication(getState() as RootState);

    if (!token) {
      console.error("ERROR: No token provided");
      throw new Error();
    }

    const result = await api.marketingApi.fetchCampaign(campaignId, token);

    if (!result) {
      throw new Error();
    }
    return result;
  }
);

export const updateCampaign = createAsyncThunk<
  Campaign | undefined,
  Campaign,
  ThunkExtra
>(
  "campaigns/updateCampaign",
  async (campaign, { getState, extra: { api } }) => {
    const { token } = selectAuthentication(getState() as RootState);

    if (!token) {
      return Promise.resolve(undefined);
    }

    return api.marketingApi.updateCampaign(campaign, token);
  }
);

export const updateCampaignStatus = createAsyncThunk<
  void,
  { campaignID: string; active: boolean },
  ThunkExtra
>(
  "campaigns/updateCampaignStatus",
  async ({ campaignID, active }, { getState, extra: { api } }) => {
    const { token } = selectAuthentication(getState() as RootState);

    if (!token) {
      return undefined;
    }

    return api.marketingApi.updateCampaignStatus(campaignID, active, token);
  }
);

export const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    activateCampaignWithId(state, action: PayloadAction<string>) {
      if (action.payload) {
        state.activeCampaign = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postCampaign.fulfilled, (state, action) => {
      console.log(action.payload);
    });
    builder.addCase(postCampaign.rejected, (state) => {
      console.log("ERROR posting Campaingn");
    });
    builder.addCase(fetchCampaigns.pending, (state) => {
      state.campaignsLoading = true;
    });
    builder.addCase(fetchCampaigns.rejected, (state) => {
      state.campaignsLoading = false;
    });
    builder.addCase(
      fetchCampaigns.fulfilled,
      (state, action: PayloadAction<Campaign[] | undefined>) => {
        state.campaignsLoading = false;
        state.campaigns = action.payload ?? [];
      }
    );
    builder.addCase(
      fetchCampaignDetail.fulfilled,
      (state, { payload }: PayloadAction<Campaign>) => {
        if (payload) {
          if (
            !state.campaigns.find((campaign) => campaign._id === payload?._id)
          ) {
            state.campaigns = [...state.campaigns, payload];
          }
        }
      }
    );
    builder.addCase(
      updateCampaignStatus.fulfilled,
      (state, action: PayloadAction<void>) => {
        
      }
    );
    builder.addCase(updateCampaign.rejected, (state) => {
      console.error("Could not update campaign");
    });
    builder.addCase(updateCampaign.pending, (state) => {
      state.campaignsLoading = true;
    });
    builder.addCase(
      updateCampaign.fulfilled,
      (state, action: PayloadAction<Campaign | undefined>) => {
        if (action.payload) {
          state.campaigns = state.campaigns.map((campaign) =>
            campaign._id === action.payload?._id ? action.payload : campaign
          );
        }
      }
    );
  },
});

export const { reducer } = campaignsSlice;
export const actions = {
  ...campaignsSlice.actions,
  postCampaign,
};

export const selectCampaignsStore = (state: RootState) => state.campaigns;

export const selectCampaigns = createSelector(
  selectCampaignsStore,
  ({ campaigns, campaignsLoading }) => ({
    campaigns,
    campaignsLoading,
  })
);

export const selectActiveCampaign = createSelector(
  selectCampaignsStore,
  ({ campaigns, activeCampaign }) => {
    return {
      activeCampaign: campaigns.find((c) => c._id === activeCampaign),
    };
  }
);
