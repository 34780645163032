import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  actions,
  deleteCampaign,
  fetchCampaignDetail,
  selectActiveCampaign,
  updateCampaign,
  updateCampaignStatus,
} from "../../Stores/campaigns";

import styles from "./CampaignDetail.module.scss";
import { ActionBar } from "../../Components/Controls/ActionBar";
import { PageContent } from "../../Components/PageContent";
import { CampaignDataTable } from "../../Components/Campaigns/CampaignDataTable";
import { CampaignDetailMain } from "../../Components/Campaigns/CampaignDetailMain";
import { Campaign } from "@stududu-gmbh/stududu-api-definitions";
import { useForm } from "react-hook-form";

export const CampaignDetail = () => {
  const dispatch = useDispatch();
  const { activeCampaign } = useSelector(selectActiveCampaign);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      dispatch(actions.activateCampaignWithId(id));
    }
  }, [dispatch, id]);

  const { setValue, watch, reset, formState } = useForm<Campaign>({
    defaultValues: activeCampaign,
  });

  useEffect(() => {
    reset(activeCampaign);
  }, [activeCampaign, reset]);

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      dispatch(fetchCampaignDetail(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (activeCampaign) {
      document.title = `${activeCampaign?.name} | ${activeCampaign?._id}`;
    }
  }, [activeCampaign]);

  const handleCampaignDelete = useCallback(
    (campaign: Campaign) => {
      dispatch(deleteCampaign(campaign));
      navigate("/campaigns");
    },
    [dispatch, navigate]
  );

  const handleBackClick = useCallback(() => {
    navigate("/campaigns");
  }, [navigate]);

  const handleCampaignUpdate = useCallback(() => {
    if (activeCampaign) {
      dispatch(updateCampaign(watch()));
    }
  }, [activeCampaign, dispatch, watch]);

  const handleCampaignActiveToggle = useCallback(() => {
    if (activeCampaign) {
      dispatch(
        updateCampaignStatus({
          campaignID: activeCampaign._id,
          active: !activeCampaign.active,
        })
      );
    }
  }, [activeCampaign, dispatch]);

  return (
    <div className={styles.campaigns}>
      <ActionBar
        backTitle="Kampagnen"
        onBackClick={handleBackClick}
        onSave={formState.isDirty ? handleCampaignUpdate : undefined}
      />
      {activeCampaign && (
        <PageContent
          mainView={
            <CampaignDetailMain
              campaign={activeCampaign}
              watch={watch}
              setValue={setValue}
              onDeleteCampaign={() => handleCampaignDelete(activeCampaign)}
            />
          }
          sideView={
            <CampaignDataTable
              campaign={activeCampaign}
              onActiveToggle={handleCampaignActiveToggle}
            />
          }
        />
      )}
    </div>
  );
};
