import { TokenResponse } from "../Models/Authentication";

export async function authenticateUser(
  userName: string,
  password: string
): Promise<string | undefined> {
  const url = "/authentication-service/api/v1/backoffice-authentication";

  const response = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ userName, password }),
  });

  if (response.ok) {
    const jsonResponse = (await response.json()) as TokenResponse;
    return jsonResponse.data.token;
  } else {
    return Promise.reject(
      response.status === 401 ? "INVALID_CREDENTIALS" : "SERVER_ERROR"
    );
  }
}
