import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "../../Components/Controls/Button";
import { TextField } from "../../Components/Controls/TextField";
import { createNewReferral } from "../../Stores/referrals";
import { actions } from "../../Stores/ui";
import styles from "./CreateReferral.module.scss";

export const CreateReferral = () => {
  const dispatch = useDispatch();
  const [campaignName, setCampaignName] = useState<string | undefined>();

  const handleNewReferral = useCallback(() => {
    if (!campaignName || campaignName === "") {
      return;
    }
    dispatch(createNewReferral(campaignName));
    dispatch(actions.closeReferralCreation());
  }, [dispatch, campaignName]);

  return (
    <div className={styles.container}>
      <h2>Neuen Link erstellen</h2>
      <TextField
        title="Anzeigename"
        value={campaignName}
        onValueChange={(value) => setCampaignName(value)}
      />
      <Button
        title={"Link erstellen"}
        className={styles.saveButton}
        onClick={handleNewReferral}
      />
    </div>
  );
};
