import { Campaign } from "@stududu-gmbh/stududu-api-definitions";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import { CalloutHeading } from "../Controls/CalloutHeading";
import { TextField } from "../Controls/TextField";
import { DangerZone, DangerZoneRow } from "../DangerZone";
import { OptionalImage } from "../OptionalImage";

import styles from "./CampaignDetailMain.module.scss";
import { CampaignCreationTarget } from "./creation/sections/CampaignCreationTarget";

interface CampaignDetailMainProps {
  watch: UseFormWatch<Campaign>;
  setValue: UseFormSetValue<Campaign>;
  campaign?: Campaign;
  onDeleteCampaign: () => void;
}

export const CampaignDetailMain = ({
  campaign,
  watch,
  setValue,
  onDeleteCampaign,
}: CampaignDetailMainProps) => {
  return (
    <>
      {!!campaign && (
        <form>
          <OptionalImage
            url={`https://banner-ad.stududu.de/${campaign._id}/billboard.jpg`}
          />
          <div className={styles.section}>
            <CalloutHeading>Allgemein</CalloutHeading>
            <TextField
              title={"Name"}
              value={watch().name}
              onValueChange={(value) => {
                setValue("name", value, { shouldDirty: true });
              }}
            />
            <TextField
              title={"Firma"}
              value={watch().company}
              onValueChange={(value) => {
                setValue("company", value, { shouldDirty: true });
              }}
            />
            <TextField
              title={"Ziellink"}
              value={watch().targetLink}
              onValueChange={(value) => {
                setValue("targetLink", value, { shouldDirty: true });
              }}
            />
            <TextField
              title={"Call to action"}
              value={watch().callToAction}
              onValueChange={(value) => {
                setValue("callToAction", value, { shouldDirty: true });
              }}
            />
          </div>
          <div className={styles.section}>
            <CalloutHeading>Zahlung</CalloutHeading>
          </div>
          <div className={styles.section}>
            <CalloutHeading>Targeting</CalloutHeading>
            <CampaignCreationTarget watch={watch} setValue={setValue} />
          </div>
          <DangerZone>
            <DangerZoneRow
              title="Kampagne unwiderbringlich löschen"
              text="Alle Daten inklusive Statistiken werden gelöscht."
              button={{
                title: "Kampagne löschen",
                onClick: () =>
                  window.confirm(
                    "Bist du sicher, dass du diese Kampagne löschen willst?"
                  ) && onDeleteCampaign(),
              }}
            />
          </DangerZone>
        </form>
      )}
    </>
  );
};
