import classNames from "classnames";
import React, { useCallback, useRef, useState } from "react";

import styles from "./Multiselect.module.scss";

import closeIcon from "../../Assets/Icons/close.svg";
import { useOutsideAlerter } from "../../Hooks/useOutsideAlerter";
import { FormLabel } from "./FormLabel";

export interface MultiselectElement {
  label: string;
  value: string | number;
}

interface MultiselectProps {
  title?: string;
  className?: string;
  elements: MultiselectElement[];
  selectedElements: (number | string)[];
  onChange: (selectedElements: (number | string)[]) => void;
  zIndex?: number;
  maxNumberOfSelections?: number;
}

export const Multiselect = ({
  elements,
  title,
  className,
  zIndex = 100,
  onChange,
  selectedElements = [],
  maxNumberOfSelections,
}: MultiselectProps) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  useOutsideAlerter(() => setDropdownVisible(false), dropdownRef);

  const handleSelectionChange = useCallback(
    (newSelectedElements: (number | string)[]) => {
      const selectedItems = elements.filter((el) =>
        newSelectedElements.includes(el.value)
      );
      onChange(selectedItems.map((el) => el.value));
    },
    [elements, onChange]
  );

  const handleValueToggle = useCallback(
    (value: number | string) => {
      if (selectedElements.includes(value)) {
        handleSelectionChange(
          selectedElements.filter((item) => item !== value)
        );
      } else {
        if (
          !maxNumberOfSelections ||
          selectedElements.length < maxNumberOfSelections
        ) {
          handleSelectionChange([value, ...selectedElements]);
        } else if (
          !maxNumberOfSelections ||
          selectedElements.length === maxNumberOfSelections
        ) {
          setDropdownVisible(false);
        }
      }
    },
    [
      selectedElements,
      handleSelectionChange,
      setDropdownVisible,
      maxNumberOfSelections,
    ]
  );

  return (
    <div className={className}>
      {title && <FormLabel title={title} />}
      <div
        className={styles.multiselect}
        onFocus={() => setDropdownVisible(true)}
      >
        <div
          className={styles.textField}
          role="button"
          tabIndex={0}
          style={{ zIndex: zIndex + 100 }}
        >
          {selectedElements.map((element, index) => (
            <MultiselectTag
              label={
                elements.find((e) => e.value === element)?.label ??
                element.toString()
              }
              onRemove={() => {
                const prevOpen = dropdownVisible;
                onChange(selectedElements.filter((el) => el !== element));
                setDropdownVisible(prevOpen);
              }}
            />
          ))}
          {selectedElements.length === 0 && (
            <p className={styles.emptySelection}>Keine Auswahl</p>
          )}
        </div>
        <div className={styles.relativeDropdownContainer}>
          <div
            className={classNames(styles.dropdown, {
              [styles.visible]: dropdownVisible,
            })}
            style={{ zIndex: zIndex }}
            ref={dropdownRef}
          >
            {elements.map((element, index) => (
              <div
                className={classNames(styles.dropdownRow, {
                  [styles.selected]: selectedElements.includes(element.value),
                })}
                key={`${element.label}-${index}`}
                style={{ zIndex: zIndex }}
                tabIndex={0}
                onClick={() => handleValueToggle(element.value)}
                onFocus={() => setDropdownVisible(true)}
              >
                <p>{element.label}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export interface MultiselectTagProps {
  label: string;
  key?: string;
  onRemove: () => void;
}

export const MultiselectTag = ({
  label,
  key,
  onRemove,
}: MultiselectTagProps) => {
  return (
    <div className={styles.multiselectTag} key={key}>
      <p>{label}</p>
      <img alt="Close Icon" src={closeIcon} onClick={onRemove}></img>
    </div>
  );
};
