import styles from "./TextField.module.scss";
import { FormLabel } from "./FormLabel";

interface TextFieldProps {
  title: string;
  value: string | number | readonly string[] | undefined;
  placeholder?: string;
  className?: string;
  type?: "text" | "password";
  onValueChange: (value: any) => void;
}

export const TextField = ({
  title,
  value = "",
  placeholder,
  onValueChange,
  type = "text",
  className,
}: TextFieldProps) => {
  return (
    <div className={className}>
      <FormLabel title={title} />
      <input
        value={value}
        onChange={(e) => onValueChange(e.target.value)}
        className={styles.textField}
        placeholder={placeholder}
        type={type}
      />
    </div>
  );
};
